import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import atob from 'atob'
import i18n from '@/libs/i18n'
import defaultState from './defaultState'

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    listviewInit: state => state.listviewInit.value,
    listviewListingsInit: state => state.listviewInit.value,
    listviewData: state => state.listviewData.value,
    listviewListings: state => state.listviewListings.value,
    listviewOrderBy: state => state.listviewOrderBy.value,
    listviewListingsOrderBy: state => state.listviewListingsOrderBy.value,
    totalRows: state => state.totalRows.value,
    totalRegsListings: state => state.totalRegsListings.value,
    listingsTotalRows: state => state.listingsTotalRows.value,
    perPage: state => state.perPage.value,
    listingsPerPage: state => state.listingsPerPage.value,
    loadingListview: state => state.loadingListview.value,
    loadingListviewListings: state => state.loadingListviewListings.value,
    currentPage: state => state.currentPage.value,
    listingsCurrentPage: state => state.listingsCurrentPage.value,
    searchTxt: state => state.searchTxt.value,
    searchListingsTxt: state => state.searchListingsTxt.value,
    fieldsOrder: state => state.aFieldsOrder,
    fieldsOrderAgents: state => state.aFieldsOrderAgents,
    sortByOptions: state => state.sortByOptions,
    totalRegs: state => state.totalRegs.value,
    listview_txt_default: state => ({
      txt: state.listview_txt_default,
      txt_not_find: state.listiview_txt_not_find,
      txt_search: state.listiview_txt_search,
      txt_listings_search: state.listiviewlistings_txt_search,
      txt_loading: state.listview_txt_loading,
    }),
    showUserShareDetails: state => state.showUserShareDetails.value,
    showListingShareDetails: state => state.showListingShareDetails.value,
    pid: state => state.pid.value,
    idListing: state => state.idListing.value,
    agent: state => state.agent.value,
    idAgent: state => state.idAgent.value,
    pageSel: state => state.pageSel.value,
  },
  mutations: {
    setLoadingListview(state, payload) {
      state.loadingListview.value = payload
    },
    setLoadingListviewListings(state, payload) {
      state.loadingListviewListings.value = payload
    },
    setListviewData(state, payload) {
      state.listviewData.value = payload
    },
    setListviewListings(state, payload) {
      state.listviewListings.value = payload
    },
    setTotalRows(state, payload) {
      state.totalRows.value = payload
    },
    setListingsTotalRows(state, payload) {
      state.listingsTotalRows.value = payload
    },
    setSearchTxt(state, payload) {
      state.searchTxt.value = payload
    },
    setSearchListingsTxt(state, payload) {
      state.searchListingsTxt.value = payload
    },
    setCurrentPage(state, payload) {
      state.currentPage.value = payload
    },
    setListingsCurrentPage(state, payload) {
      state.listingsCurrentPage.value = payload
    },
    setListviewOrderBy(state, payload) {
      state.listviewOrderBy.value = {
        sort: payload.sort,
        column: payload.column,
      }
    },
    setListviewListingsOrderBy(state, payload) {
      state.listviewListingsOrderBy.value = {
        sort: payload.sort,
        column: payload.column,
      }
    },
    setListviewInit(state, payload) {
      state.listviewInit.value = payload
    },
    setListviewListingsInit(state, payload) {
      state.listviewListingsInit.value = payload
    },
    setTotalRegs(state, payload) {
      state.totalRegs.value = payload
    },
    setTotalRegsListings(state, payload) {
      state.totalRegsListings.value = payload
    },
    setShowUserShareDetails(state, payload) {
      state.showUserShareDetails.value = payload
    },
    setShowListingShareDetails(state, payload) {
      state.showListingShareDetails.value = payload
    },
    setPid(state, payload) {
      state.pid.value = payload
    },
    setIdListing(state, payload) {
      state.idListing.value = payload
    },
    setAgent(state, payload) {
      state.agent.value = payload
    },
    setIdAgent(state, payload) {
      state.idAgent.value = payload
    },
    setPageSel(state, payload) {
      state.pageSel.value = payload
    },
  },
  actions: {
    // Carregar listagem
    fetchListView({
      state, commit, dispatch,
    }, payload) {
      const listviewFilterParams = new FormData()
      let loadListviewCustom = ''

      if (state.pageSel.value === 'listings-myShares') {
        listviewFilterParams.append('pageListing', 'myShares')
        loadListviewCustom = 'listings/loadListingSharedConsultores'
      } else if (state.pageSel.value === 'listings-shares') {
        listviewFilterParams.append('pageListing', 'shared')
        loadListviewCustom = 'listings/loadListingSharedConsultores'
      } else if (state.pageSel.value === 'listings-shared') {
        listviewFilterParams.append('pageListing', 'sharedListings')
        loadListviewCustom = 'listings/loadListingSharedConsultores'
      } else if (state.pageSel.value === 'listings-shared-HUB') {
        listviewFilterParams.append('pageListing', 'sharedListingsHUB')
        loadListviewCustom = 'listings/loadListingSharedConsultores'
      } else if (state.pageSel.value === 'listings-shared-byConsultant') {
        loadListviewCustom = 'listings/loadListingsSharedByConsultant'
      } else if (state.pageSel.value === 'listings-shared-byConsultantHUB') {
        loadListviewCustom = 'listings/loadListingsSharedByConsultantHUB'
      } else if (state.pageSel.value === 'mylink') {
        loadListviewCustom = 'listings/loadListingSharedConsultores'
        listviewFilterParams.append('pageListing', 'mylink')
      } else {
        listviewFilterParams.append('pageListing', 'myShares')
        loadListviewCustom = 'listings/loadListingSharedConsultores'
      }

      if (state.pageSel.value !== 'listings-shared-byConsultant' && state.pageSel.value !== 'listings-shared-byConsultantHUB') {
        listviewFilterParams.append('sw012s01', atob(state.idListing.value))
      }

      listviewFilterParams.append('start', ((state.currentPage.value - 1) * state.perPage.value))
      listviewFilterParams.append('length', state.perPage.value)

      if (state.searchTxt.value !== '') {
        listviewFilterParams.append('search[value]', state.searchTxt.value)
        listviewFilterParams.append('search[regex]', false)
      }

      if (state.listviewOrderBy.value.column !== undefined) {
        listviewFilterParams.append('order[0][column]', state.listviewOrderBy.value.column)
      }

      if (state.listviewOrderBy.value.sort !== undefined) {
        listviewFilterParams.append('order[0][dir]', state.listviewOrderBy.value.sort)
      }

      if (!!payload && (payload.loadingDisable !== undefined) && (payload.loadingDisable === true)) {
        // Nao executa o loading
      } else {
        commit('setLoadingListview', true)
        commit('setListviewInit', 1)
      }

      return new Promise((resolve, reject) => { // reject
        dispatch('request/cancelTokensPending', null, { root: true })

        api.post(`${apiConfig.url_base_api}${loadListviewCustom}`, listviewFilterParams)
          .then(response => {
            commit('setListviewData', response.data.data)
            commit('setTotalRegs', response.data.recordsTotal)
            commit('setTotalRows', response.data.recordsTotal)
            commit('setLoadingListview', false)
            commit('setListviewInit', 0)
            resolve(true)
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              commit('setListviewData', [])
              commit('setTotalRegs', 0)
              commit('setTotalRows', 0)
              commit('setLoadingListview', false)
              commit('setListviewInit', 0)
              reject(new Error(i18n.t('Problema ao carregar a listagem')))
            }
          })
      })
    },

    // Carregar listagem imóveis partilhados
    fetchListShareView({
      state, commit, dispatch,
    }, payload) {
      const listviewFilterParams = new FormData()
      let loadListviewCustom = ''

      if (state.pageSel.value === 'listings-shared-byConsultant') {
        listviewFilterParams.append('pageListing', 'sharedByConsultant')
        loadListviewCustom = 'listings/loadListingConsultorShare'
      } else if (state.pageSel.value === 'listings-shared-byConsultantHUB') {
        listviewFilterParams.append('pageListing', 'sharedByConsultantHUB')
        loadListviewCustom = 'listings/loadListingConsultorShare'
      }

      listviewFilterParams.append('sw001s01', atob(state.idAgent.value))
      listviewFilterParams.append('start', (state.listingsCurrentPage.value - 1))
      listviewFilterParams.append('length', state.listingsPerPage.value)

      if (state.searchListingsTxt.value !== '') {
        listviewFilterParams.append('search[value]', state.searchListingsTxt.value)
        listviewFilterParams.append('search[regex]', false)
      }

      if (state.listviewListingsOrderBy.value.column !== undefined) {
        listviewFilterParams.append('order[0][column]', state.listviewListingsOrderBy.value.column)
      }

      if (state.listviewListingsOrderBy.value.sort !== undefined) {
        listviewFilterParams.append('order[0][dir]', state.listviewListingsOrderBy.value.sort)
      }

      if (!!payload && (payload.loadingDisable !== undefined) && (payload.loadingDisable === true)) {
        // Nao executa o loading
      } else {
        commit('setLoadingListviewListings', true)
        commit('setListviewListingsInit', 1)
      }

      return new Promise((resolve, reject) => { // reject
        dispatch('request/cancelTokensPending', null, { root: true })

        api.post(`${apiConfig.url_base_api}${loadListviewCustom}`, listviewFilterParams)
          .then(response => {
            commit('setListviewListings', response.data.data)
            commit('setTotalRegsListings', response.data.recordsTotal)
            commit('setLoadingListviewListings', false)
            commit('setListviewListingsInit', 0)
            resolve(true)
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              commit('setListviewListings', [])
              commit('setTotalRegsListings', 0)
              commit('setLoadingListviewListings', false)
              commit('setListviewListingsInit', 0)
              reject(new Error(i18n.t('Problema ao carregar a listagem')))
            }
          })
      })
    },

    // Ordenação da listagem
    listviewFilterOrderBy({ state, commit, dispatch }, field) {
      if ((!!state.listviewOrderBy.value.column) && (state.listviewOrderBy.value.column === field)) {
        if (state.listviewOrderBy.value.sort === 'asc') {
          commit('setListviewOrderBy', {
            sort: 'desc',
            column: field,
          })
        } else {
          commit('setListviewOrderBy', {
            sort: 'asc',
            column: field,
          })
        }
      } else {
        commit('setListviewOrderBy', {
          sort: 'asc',
          column: field,
        })
      }

      return dispatch('fetchListView')
    },

    // Ordenação da listagem
    listviewListingsFilterOrderBy({ state, commit, dispatch }, field) {
      if ((!!state.listviewListingsOrderBy.value.column) && (state.listviewListingsOrderBy.value.column === field)) {
        if (state.listviewListingsOrderBy.value.sort === 'asc') {
          commit('setListviewListingsOrderBy', {
            sort: 'desc',
            column: field,
          })
        } else {
          commit('setListviewListingsOrderBy', {
            sort: 'asc',
            column: field,
          })
        }
      } else {
        commit('setListviewListingsOrderBy', {
          sort: 'asc',
          column: field,
        })
      }

      return dispatch('fetchListShareView')
    },

    setShowUserShareDetails({ commit, dispatch }, params) {
      commit('setShowUserShareDetails', params[0])

      if (params[1] !== null && params[2] !== null) {
        commit('setIdListing', params[1])
        commit('setPid', params[2])
        if (params[3] !== 'undefined' && params[3] !== null && params[3] === true) {
          commit('setPageSel', 'mylink')
        }
        return dispatch('fetchListView')
      }

      return true
    },

    setShowListingShareDetails({ commit, dispatch }, params) {
      commit('setShowListingShareDetails', params[0])
      if (params[1] !== null && params[2] !== null) {
        commit('setIdAgent', params[1])
        commit('setAgent', params[2])
        dispatch('fetchListShareView')
      }
    },

    setPageSel({ commit }, payload) {
      commit('setPageSel', payload.pageSel)
    },

    setSearchTxt({ commit, dispatch }, payload) {
      commit('setSearchTxt', payload)
      dispatch('fetchListView')
    },

    setSearchListingsTxt({ commit, dispatch }, payload) {
      commit('setSearchListingsTxt', payload)
      dispatch('fetchListShareView')
    },

    setCurrentPage({ commit, dispatch }, value) {
      commit('setCurrentPage', value)
      return dispatch('fetchListView', { skipCurrentPage: true })
    },

    setListingsCurrentPage({ commit, dispatch }, value) {
      commit('setCurrentPage', value)
      return dispatch('setListingsCurrentPage', { skipCurrentPage: true })
    },

    checkShareAvailable({ dispatch }, { shareId, retry = 10 }) {
      return new Promise((resolve, reject) => {
        api.get(`${apiConfig.url_base_api}listings/partilha/checkShareUrlAvailable/${shareId}`)
          .then(async response => {
            if (response.data.available !== undefined && response.data.available) {
              resolve(true)
            } else if (retry <= 0) {
              reject()
            } else {
              await new Promise(r => setTimeout(r, 20000))
              const result = await dispatch('checkShareAvailable', { shareId, retry: retry - 1 })
              resolve(result)
            }
          })
      })
    },
  },
}
