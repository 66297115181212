<template>
  <div>
    <ul
      v-if="quickViewGeneral !== true"
      class="email-media-list"
    >
      <b-media
        v-for="(row) in listviewData"
        :key="row.id"
        tag="li"
        no-body
      >
        <b-media-aside class="media-left mr-50">
          <!-- Fotografia -->
          <b-avatar
            class="avatar"
            size="40"
            variant="primary"
            :src="row.imgAgent"
          />
        </b-media-aside>
        <b-media-body>
          <b-row>
            <b-col md="4">
              <div class="mail-details">
                <div class="mail-items">
                  <h5
                    v-if="row.nameAgent"
                    class="mb-25"
                  >
                    {{ row.nameAgent }} - {{ row.hubAgent }}
                  </h5>
                  <span
                    v-if="row.emailAgent"
                    class="text-truncate"
                    :href="`mailto:${row.emailAgent}`"
                  >
                    <feather-icon
                      icon="MailIcon"
                    />
                    {{ row.emailAgent }}
                  </span>
                  <span
                    v-if="row.phoneAgent"
                    :href="`tel:${row.phoneAgent}`"
                    :class="[{'text-truncate d-none d-sm-inline': true, 'px-2': ((row.emailAgent) ? true : false)}]"
                  >
                    <feather-icon
                      icon="PhoneCallIcon"
                    />
                    {{ row.phoneAgent }}
                  </span>
                  <span
                    v-if="row.phoneAgent"
                    :href="`tel:${row.phoneAgent}`"
                    :class="[{'text-truncate d-inline d-sm-none': true}]"
                  >
                    <feather-icon
                      icon="PhoneCallIcon"
                    />
                    {{ row.phoneAgent }}
                  </span>
                </div>
              </div>
              <div
                v-if="row.cargoAgent"
                class="mail-message"
              >
                <p class="text-truncate mb-0">
                  {{ row.cargoAgent }}
                </p>
              </div>
            </b-col>
            <b-col
              md="2"
              class="mt-1"
            >
              <span
                v-if="row.teamShare"
              >
                {{ row.teamShare }}
              </span>
              <div
                v-if="row.teamShare"
                class="mail-message"
              >
                <p class="mb-0">
                  {{ $t('Em nome') }}
                </p>
              </div>
            </b-col>
            <b-col
              v-if="row.shareVR === 1"
              md="2"
              class="mt-1"
            >
              <span>
                {{ $t('Sim') }}
              </span>
              <div class="mail-message">
                <p class="mb-0">
                  {{ $t('Incluí vídeo/VR') }}
                </p>
              </div>
            </b-col>
            <b-col
              v-else
              md="2"
              class="mt-1"
            >
              <span>
                {{ $t('Não') }}
              </span>
              <div class="mail-message">
                <p class="mb-0">
                  {{ $t('Incluí vídeo/VR') }}
                </p>
              </div>
            </b-col>
            <b-col
              md="2"
              class="mt-1"
            >
              <feather-icon
                icon="CircleIcon"
                :fill="row.available === 0 ? '#F3C200' : (row.available === 1 ? '#1BBC9B' : '#000')"
                stroke-width="0"
                size="14"
              />
              <span v-if="row.available===0">
                {{ $t('Em processamento') }}
              </span>
              <span v-else-if="row.available===1">
                {{ $t('Ativa') }}
              </span>
              <span v-else>
                {{ $t('Inativa') }}
              </span>
              <div class="mail-message">
                <p class="mb-0">
                  {{ $t('Estado') }}
                </p>
              </div>
              <p
                v-if="row.available!==1"
                class="small mb-0 mt-1"
              >
                {{ row.shareStatusTxt }}
              </p>
            </b-col>
            <b-col md="3">
              <div class="mail-message mt-2">
                <b-button
                  v-if="row.urlShare"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-info"
                  size="sm"
                  target="_blank"
                  :href="row.urlShare"
                >
                  <feather-icon
                    icon="Share2Icon"
                    class="mr-50"
                  />
                  <span class="align-middle">{{ $t('Ver partilha') }}</span>
                </b-button>
                <b-button
                  v-if="row.urlShare"
                  v-clipboard:copy="row.urlShare"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-secondary"
                  size="sm"
                  class="ml-50"
                  @click="copyClipboard()"
                >
                  <feather-icon
                    icon="CopyIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">{{ $t('Copiar link') }}</span>
                </b-button>
                <b-button
                  v-if="row.idAgent"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-info"
                  size="sm"
                  @click="setShowListingShareDetails([true, row.idAgent, row.nameAgent])"
                >
                  <feather-icon
                    icon="Share2Icon"
                    class="mr-50"
                  />
                  <span class="align-middle">{{ $t('Ver partilhas') }}</span>
                </b-button>
              </div>
            </b-col>

          </b-row>
        </b-media-body>
      </b-media>
    </ul>
    <b-row
      v-for="(row, index) in listviewData"
      v-else
      :key="row.id"
      :class="index !== 0 ? 'mt-2' : ''"
    >
      <b-col md="12">
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="row.imgAgent"
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h6 class="mb-0">
                {{ row.hubAgent }} - {{ row.nameAgent }}
              </h6>
              <span class="card-text">
                <template v-if="row.emailAgent">
                  <b-link :href="`mailto:${row.emailAgent}`">
                    {{ row.emailAgent }}
                  </b-link>
                </template>
              </span>
              <p>
                <span class="card-text">
                  <template v-if="row.phoneAgent">
                    <b-link :href="`tel:${row.phoneAgent.trim()}`">
                      {{ row.phoneAgent }}
                    </b-link>
                  </template>
                </span>
              </p>
            </div>
          </div>
        </div>
      </b-col>
      <b-col md="12">
        <b-form-group
          :label="$t('Em nome')"
        >
          <p class="text-primary mb-0">
            {{ row.teamShare || '----' }}
          </p>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          :label="$t('Incluí vídeo/VR')"
        >
          <p
            v-if="row.shareVR === 1"
            class="text-primary mb-0"
          >
            {{ $t('Sim') }}
          </p>
          <p
            v-else
            class="text-primary mb-0"
          >
            {{ $t('Não') }}
          </p>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          :label="$t('Estado')"
        >
          <feather-icon
            icon="CircleIcon"
            :fill="row.available === 0 ? '#F3C200' : (row.available === 1 ? '#1BBC9B' : '#000')"
            stroke-width="0"
            size="14"
          />
          <p
            v-if="row.available===0"
            class="text-primary mb-0"
          >
            {{ $t('Em processamento') }}
          </p>
          <p
            v-else-if="row.available===1"
            class="text-primary mb-0"
          >
            {{ $t('Ativa') }}
          </p>
          <p
            v-else
            class="text-primary mb-0"
          >
            {{ $t('Inativa') }}
          </p>
          <p
            v-if="row.available!==1"
            class="small mb-0 mt-1"
          >
            {{ row.shareStatusTxt }}
          </p>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <div
          class="d-flex p-1 justify-content-center"
        >
          <b-button
            v-if="row.urlShare"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-info"
            size="sm"
            target="_blank"
            :href="row.urlShare"
          >
            <feather-icon
              icon="Share2Icon"
              class="mr-50"
            />
            <span class="align-middle">{{ $t('Ver partilha') }}</span>
          </b-button>
          <b-button
            v-if="row.urlShare"
            v-clipboard:copy="row.urlShare"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-secondary"
            size="sm"
            class="ml-50"
            @click="copyClipboard()"
          >
            <feather-icon
              icon="CopyIcon"
              class="mr-50"
            />
            <span class="align-middle">{{ $t('Copiar link') }}</span>
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
import {
  BMedia, BMediaAside, BMediaBody, BAvatar, BButton, BRow, BCol, BLink, BFormGroup,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import { Dialog } from '@capacitor/dialog'
import useCapacitor from '@core-custom/utils/capacitor'

Vue.use(VueClipboard)

export default {
  components: {
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BButton,
    BRow,
    BCol,
    BLink,
    BFormGroup,
  },
  directives: {
    Ripple,
  },
  props: {
    quickViewGeneral: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters('share', ['listviewData']),
  },
  methods: {
    async showMsgErrorRequest(error) {
      if (useCapacitor.isNative() === true) {
        Dialog.alert({
          title: this.$t('Alerta'),
          message: error.message,
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('Alerta'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.message,
          },
        })
      }
    },
    copyClipboard() {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: this.$t('Sucesso'),
          icon: 'CheckIcon',
          variant: 'success',
          text: this.$t('Link copiado para o clipboard'),
        },
      })
    },
    setShowListingShareDetails(newValue) {
      this.$store.dispatch('share/setShowListingShareDetails', newValue).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    checkShareAvailable() {
      this.listviewData.filter(share => !share.available).forEach((share, index) => {
        this.$store.dispatch('share/checkShareAvailable', { shareId: share.shareId }).then(res => {
          if (res) {
            this.listviewData[index].available = true
            this.$store.commit('share/setListviewData', this.listviewData)
          }
        })
      })
    },
  },
}
</script>
