<template>
  <div>
    <h6
      v-if="showFilterTipologia.showcltipo > 0 && countryApp.toUpperCase() === 'PT'"
      class="section-label mt-3 mb-1 px-2"
    >
      {{ $t('Tipologia') }}
    </h6>
    <v-select
      v-if="showFilterTipologia.showcltipo > 0 && countryApp.toUpperCase() === 'PT'"
      v-model="selectedDataFiltersListview.fsw012s08_desde"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :options="dataFiltersListview.classesTipologias"
      class="mb-1 ml-2 mr-2"
      label="sw005s02"
      item-text="sw005s02"
      item-value="sw005s01"
      append-to-body
      :calculate-position="withPopper"
      @input="selectFilterOptions(['fsw012s08_desde', $event])"
    >
      <template #option="{ sw005s02 }">
        {{ sw005s02 }}
      </template>
      <div slot="no-options">
        {{ $t('Sem resultados') }}
      </div>
    </v-select>

    <h6
      v-if="showFilterTipologia.showcltipo > 0 && countryApp.toUpperCase() === 'PT'"
      class="section-label text-center mt-1 mb-1 px-2"
    >
      {{ $t('a') }}
    </h6>

    <v-select
      v-if="showFilterTipologia.showcltipo > 0 && countryApp.toUpperCase() === 'PT'"
      v-model="selectedDataFiltersListview.fsw012s08_ate"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :options="dataFiltersListview.classesTipologias"
      class="mb-1 ml-2 mr-2"
      label="sw005s02"
      item-text="sw005s02"
      item-value="sw005s01"
      append-to-body
      :calculate-position="withPopper"
      @input="selectFilterOptions(['fsw012s08_ate', $event])"
    >
      <template #option="{ sw005s02 }">
        {{ sw005s02 }}
      </template>
      <div slot="no-options">
        {{ $t('Sem resultados') }}
      </div>
    </v-select>

    <h6
      v-if="showFilterTipologia.showcltipo > 0 && Number(selectedDataFiltersListview.tipoImovel) !== 6 && countryApp.toUpperCase() !== 'PT'"
      class="section-label mt-3 mb-1 px-2"
    >
      {{ $t('Quartos') }}
    </h6>
    <v-select
      v-if="showFilterTipologia.showcltipo > 0 && Number(selectedDataFiltersListview.tipoImovel) !== 6 && countryApp.toUpperCase() !== 'PT'"
      v-model="selectedDataFiltersListview.fsw012s08_desde"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :options="dataFiltersListview.array70"
      class="mb-1 ml-2 mr-2"
      label="desc"
      item-text="desc"
      item-value="id"
      append-to-body
      :calculate-position="withPopper"
      @input="selectFilterOptions(['fsw012s08_desde', $event])"
    >
      <template #option="{ desc }">
        {{ desc }}
      </template>
      <div slot="no-options">
        {{ $t('Sem resultados') }}
      </div>
    </v-select>

    <h6
      v-if="showFilterTipologia.showcltipo > 0 && Number(selectedDataFiltersListview.tipoImovel) !== 6 && countryApp.toUpperCase() !== 'PT'"
      class="section-label text-center mt-1 mb-1 px-2"
    >
      {{ $t('a') }}
    </h6>

    <v-select
      v-if="showFilterTipologia.showcltipo > 0 && Number(selectedDataFiltersListview.tipoImovel) !== 6 && countryApp.toUpperCase() !== 'PT'"
      v-model="selectedDataFiltersListview.fsw012s08_ate"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :options="dataFiltersListview.array70"
      class="mb-1 ml-2 mr-2"
      label="desc"
      item-text="desc"
      item-value="id"
      append-to-body
      :calculate-position="withPopper"
      @input="selectFilterOptions(['fsw012s08_ate', $event])"
    >
      <template #option="{ desc }">
        {{ desc }}
      </template>
      <div slot="no-options">
        {{ $t('Sem resultados') }}
      </div>
    </v-select>

    <h6
      v-if="(countryApp.toUpperCase() === 'PT' && showFilterTipologia.showTipoTerreno > 0) || (countryApp.toUpperCase() !== 'PT' && Number(selectedDataFiltersListview.tipoImovel) === 6)"
      class="section-label mt-3 mb-1 px-2"
    >
      {{ $t('Tipo de terreno') }}
    </h6>
    <v-select
      v-if="(countryApp.toUpperCase() === 'PT' && showFilterTipologia.showTipoTerreno > 0) || (countryApp.toUpperCase() !== 'PT' && Number(selectedDataFiltersListview.tipoImovel) === 6)"
      v-model="selectedDataFiltersListview.fsw012s09"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :options="dataFiltersListview.tiposTerrenos"
      class="mb-1 ml-2 mr-2"
      label="sw008s02"
      item-text="sw008s02"
      item-value="sw008s01"
      append-to-body
      :calculate-position="withPopper"
      @input="selectFilterOptions(['fsw012s09', $event])"
    >
      <template #option="{ sw008s02 }">
        {{ sw008s02 }}
      </template>
      <div slot="no-options">
        {{ $t('Sem resultados') }}
      </div>
    </v-select>

    <h6
      class="section-label mt-3 mb-1 px-2"
    >
      {{ $t('Intervalo de preço') }}
    </h6>
    <div
      class="form-group-compose mb-1 ml-2 mr-2"
    >
      <b-input-group
        :prepend="symbolMoney"
        append=""
        class="input-group-merge"
      >
        <b-form-input
          v-model.lazy="selectedDataFiltersListview.fsw012s14_desde"
          v-currency="configMoney"
          placeholder=""
          autocomplete="off"
          @input="selectFilterOptions(['fsw012s14_desde', $event])"
        />
      </b-input-group>

      <h6
        class="section-label text-center mt-1 mb-1 px-2"
      >
        {{ $t('a') }}
      </h6>

      <b-input-group
        :prepend="symbolMoney"
        append=""
        class="input-group-merge"
      >
        <b-form-input
          v-model="selectedDataFiltersListview.fsw012s14_ate"
          v-currency="configMoney"
          placeholder=""
          autocomplete="off"
          @input="selectFilterOptions(['fsw012s14_ate', $event])"
        />
      </b-input-group>
    </div>

    <h6
      class="section-label mt-3 mb-1 px-2"
    >
      {{ $t('Condição do imóvel') }}
    </h6>
    <v-select
      id="fsw012s11"
      v-model="selectedDataFiltersListview.fsw012s11"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :options="dataFiltersListview.condicoes"
      class="mb-1 ml-2 mr-2"
      multiple
      label="sw010s02"
      item-text="sw010s02"
      item-value="sw010s01"
      append-to-body
      :calculate-position="withPopper"
      @input="selectFilterOptions(['fsw012s11', $event])"
    >
      <template #option="{ sw010s02 }">
        {{ sw010s02 }}
      </template>
      <div slot="no-options">
        {{ $t('Sem resultados') }}
      </div>
    </v-select>

    <h6
      class="section-label mt-3 mb-1 px-2"
    >
      {{ $t('Aceita permuta') }}
    </h6>
    <b-form-radio
      v-model="selectedDataFiltersListview.fsw012s26"
      value="0"
      class="mb-1 ml-2"
      @change="selectFilterOptions(['fsw012s26', 0])"
    >
      {{ $t('Todos') }}
    </b-form-radio>
    <div
      v-for="row in dataFiltersListview.permutas"
      :key="`tipologia-${row.id}`"
    >
      <b-form-radio
        v-model="selectedDataFiltersListview.fsw012s26"
        :value="row.id"
        class="mb-1 ml-2 mr-2"
        @change="selectFilterOptions(['fsw012s26', row.id])"
      >
        {{ row.desc }}
      </b-form-radio>
    </div>

    <component
      :is="blockLocalization"
      ref="location"
      @updateValueLocalization="updateValueLocalization"
    />

    <h6
      class="section-label mt-3 mb-1 px-2"
    >
      {{ $t('Atributos') }}
    </h6>
    <b-form-checkbox-group
      v-if="[5 ,6, 9, 10, 13, 11, 12].includes(Number(currentUser.id_role))"
      id="checkbox-group-2"
      v-model="selectedDataFiltersListview.atributo"
      name="flavour-2"
      class="mb-1 ml-2"
    >
      <div
        v-for="(row, index) in dataFiltersListview.atributos"
        :key="`atributo-${index}`"
      >
        <b-form-checkbox
          :value="row.id"
          class="mb-1"
          @change="selectAtributo(row.id);$emit('close-left-sidebar')"
        >
          {{ row.desc }}
        </b-form-checkbox>
      </div>
    </b-form-checkbox-group>
    <b-form-checkbox-group
      v-else
      id="checkbox-group-2"
      v-model="selectedDataFiltersListview.atributo"
      name="flavour-2"
      class="mb-1 ml-2"
    >
      <div
        v-for="(row, index) in dataFiltersListview.atributosG"
        :key="`atributo-${index}`"
      >
        <b-form-checkbox
          :value="row.id"
          class="mb-1"
          @change="selectAtributo(row.id);$emit('close-left-sidebar')"
        >
          {{ row.desc }}
        </b-form-checkbox>
      </div>
    </b-form-checkbox-group>

    <h6
      class="section-label mt-3 mb-1 px-2"
    >
      {{ $t('Por data de') }}
    </h6>
    <v-select
      id="fsw012_searchadv_tipo_datas"
      v-model="selectedDataFiltersListview.data"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :options="dataFiltersListview.optionsData"
      class="mb-1 ml-2 mr-2"
      label="desc"
      item-text="desc"
      item-value="id"
      append-to-body
      :calculate-position="withPopper"
      @input="selectFilterOptions(['fsw012_searchadv_tipo_datas', $event])"
    >
      <template #option="{ desc }">
        {{ desc }}
      </template>
      <div slot="no-options">
        {{ $t('Sem resultados') }}
      </div>
    </v-select>
    <b-form-group
      v-if="selectedDataFiltersListview.data != ''"
      class="text-left mt-2"
    >
      <div
        v-for="row in dataFiltersListview.datas"
        :key="`tipologia-${row.id}`"
      >
        <b-form-radio
          v-model="selectedDataFiltersListview.tipoData"
          :value="row.id"
          class="mb-1 ml-2 mr-2"
          @change="selectTipoData(row.id);$emit('close-left-sidebar')"
        >
          {{ row.desc }}
        </b-form-radio>
      </div>
    </b-form-group>
    <b-form-group
      v-if="selectedDataFiltersListview.tipoData == '5'"
      class="text-left mt-2 ml-2 mr-2"
    >
      <b-input-group>
        <date-picker
          v-model="selectedDataFiltersListview.intervaloData"
          value-type="format"
          input-class="form-control"
          type="date"
          :editable="false"
          :clearable="true"
          :lang="langCalendar"
          :append-to-body="true"
          prefix-class="sw"
          @input="selectFilterIntervaloData($event)"
        >
          <template #icon-clear>
            <v-select-deselect />
          </template>
        </date-picker>
      </b-input-group>
      <h6
        class="section-label text-center mt-1 mb-1"
      >
        {{ $t('a') }}
      </h6>
      <b-input-group>
        <date-picker
          v-model="selectedDataFiltersListview.intervaloDataEnd"
          value-type="format"
          input-class="form-control"
          type="date"
          :editable="false"
          :clearable="true"
          :lang="langCalendar"
          :append-to-body="true"
          prefix-class="sw"
          @input="selectFilterIntervaloDataEnd($event)"
        >
          <template #icon-clear>
            <v-select-deselect />
          </template>
        </date-picker>

      </b-input-group>
    </b-form-group>

    <!-- Extra space -->
    <h6
      class="section-label mt-2 mb-1 px-2"
    >
      {{ '\xa0' }}
    </h6>

  </div>
</template>

<script>

import {
  BFormCheckbox, BFormCheckboxGroup, BFormRadio, BFormGroup, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters, mapActions } from 'vuex'
import { mask } from 'vue-the-mask'
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'
import { CurrencyDirective } from 'vue-currency-input'
import { configMoney, symbolMoney } from '@core-custom/utils/money'
import { eventsCustomSelects } from '@core-custom/mixins/geral'

const oDatepicker = () => Promise.all([
  import(/* webpackChunkName: "chunk-vue2-datepicker" */ 'vue2-datepicker'),
  import(/* webpackChunkName: "chunk-vue2-datepicker" */ '@/assets/scss/datepicker.scss'),
]).then(([DatePicker]) => DatePicker)

export default {
  directives: {
    Ripple,
    mask,
    currency: CurrencyDirective,
  },
  components: {
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormRadio,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormInput,
    Cleave,
    vSelectDeselect: vSelect.components.Deselect,
    DatePicker: oDatepicker,
  },
  mixins: [eventsCustomSelects],
  data() {
    return {
      configMoney,
      symbolMoney,
      langCalendar: {},
      blockLocalization: null,
      fetchListViewActive: false,
    }
  },
  computed: {
    ...mapGetters('listings', ['dataFiltersListview', 'selectedDataFiltersListview', 'showFilterTipologia']),
    ...mapGetters('auth', ['countryApp', 'currentUser']),
  },
  async mounted() {
    await import(`@/views/modules/components/localization/country/filter/${this.countryApp}.vue`).then(res => {
      this.blockLocalization = res.default
    })

    await this.importLocale(this.$i18n.locale).then(res => {
      this.langCalendar = res.default
      // Forçei estes valores para a semana começar sempre no domingo
      this.langCalendar.formatLocale.firstDayOfWeek = 0
      this.langCalendar.formatLocale.firstWeekContainsDate = 4
    })

    await this.$nextTick()

    await this.loadDataLocation()

    await this.$nextTick()
  },
  methods: {
    ...mapActions('listings', ['selectFilterOptions', 'selectAtributo', 'selectTipoData', 'selectFilterIntervaloData', 'selectFilterIntervaloDataEnd']),
    async importLocale(lang) {
      return import(`vue2-datepicker/locale/${lang}`)
    },
    async updateValueLocalization(payload) {
      await payload.then(result => {
        if (this.countryApp === 'pt') {
          this.$store.commit('listings/setDistritos', result.level1)
          this.$store.commit('listings/setConcelhos', result.level2)
          this.$store.commit('listings/setFreguesias', result.level3)
        }

        if (this.countryApp === 'es') {
          this.$store.commit('listings/setComunidades', result.level1)
          this.$store.commit('listings/setProvincias', result.level2)
          this.$store.commit('listings/setComarcas', result.level3)
          this.$store.commit('listings/setMunicipios', result.level4)
          this.$store.commit('listings/setZonas', result.level5)
        }
      })

      if (this.fetchListViewActive === true) {
        await this.$store.dispatch('listings/fetchListView')
      }
    },
    async loadDataLocation() {
      if (this.$refs.location) {
        await this.$refs.location.loadDataForm({
          level1: this.selectedDataFiltersListview.fsw012s28 || [],
          level2: this.selectedDataFiltersListview.fsw012s29 || [],
          level3: this.selectedDataFiltersListview.fsw012s30 || [],
        }).then(success => {
          if (success === true) {
            this.fetchListViewActive = true
          }
        })
      }
    },
  },
}
</script>
