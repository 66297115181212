import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'

const defaultState = () => ({
  loadingListview: ref(true),
  listings: ref({}),
  totalListings: ref(0),
  currentPage: ref(1),
  perPage: ref(21),
  searchTxt: ref(''),
  listview_txt_default: i18n.t('Nenhum resultado disponível'),
  listiview_txt_not_find: i18n.t('Não encontrou nenhum resultado'),
  listiview_txt_search: i18n.t('ZMID'),
  listview_txt_loading: i18n.t('A carregar registos ...'),
  sortBy: ref({ text: `${i18n.t('Modificação:')} ${i18n.t('Recentes')}`, value: 1 }),
  estado: ref(['1']),
  estadoExterna: ref(['5#1']),
  tipoNegocio: ref([0]),
  tipologia: ref([0]),
  tipoImovel: ref(0),
  atributo: ref([]),
  data: ref(''),
  tipoData: ref(''),
  intervaloData: ref(''),
  intervaloDataEnd: ref(''),
  appConfig: ref({}),
  loadData: ref(0),
  filterEstadoAng: ref({}),
  filterTiposImoveis: ref({}),
  filterTipoNegocios: ref({}),
  filterTipologiasMisto: ref({}),
  filterCondicoesImovel: ref({}),
  filterClassesTipologias: ref({}),
  filterTiposTerrenos: ref([]),
  filterLoad: ref(false),
  listviewOrderBy: ref({
    column: 'sw012v63',
    sort: 'desc',
  }),
  listviewInit: ref(1),
  positionListview: {},
  pageSel: ref(''),
  filterSelectedTotalFields: ref(0),
  buttonSearch: ref('outline-primary'),
  showNewListing: ref(false),
  showOptionsListing: ref(false),
  pid: ref(''),
  idListing: ref(''),
  optionsListing: ref([]),
  agentID: ref(''),
  filterLeads: ref([]),
  filterBanks: ref([]),
  optionLeadSelected: ref(''),
  optionBankSelected: ref(''),
  fsw012s08_desde: ref(''),
  fsw012s08_ate: ref(''),
  fsw012s14_desde: ref(0),
  fsw012s14_ate: ref(0),
  fsw012s11: ref(''),
  fsw012s26: ref(''),
  fsw012s28: ref(''),
  fsw012s29: ref(''),
  fsw012s30: ref(''),
  fsw012s298: ref(''),
  fsw012s331: ref(''),
  fsw012s09: ref(''),
  activeFetchListView: ref(false),
  pageLoadSel: ref(''),
  showListingQuickView: ref(false),
  sortByOptions: [
    {
      name: 'sw012v63',
      txt: i18n.t('Modificação'),
    },
    {
      name: 'sw012v61',
      txt: i18n.t('Entradas'),
    },
    {
      name: 'sw012v02',
      txt: i18n.t('ZMID'),
    },
    {
      name: 'sw018v02',
      txt: i18n.t('Estado'),
    },
    {
      name: 'sw004v02',
      txt: i18n.t('Tipologia'),
    },
    {
      name: 'sw016v02',
      txt: i18n.t('Local'),
    },
    {
      name: 'sw012v14v69',
      txt: i18n.t('Preço'),
    },
  ],
  filterEstadosExternas: ref([
    {
      id: '5#1',
      desc: i18n.t('Externas'),
    },
    {
      id: '2#3#7',
      desc: i18n.t('Externas em PV'),
    },
    {
      id: 'lixo',
      desc: i18n.t('Lixo'),
    },
  ]),
  filterTipologias: ref([]),
  filterTipologiasIni: ref([]),
  filterPermutas: ref([
    {
      id: '1',
      desc: i18n.t('Sim'),
    },
    {
      id: '2',
      desc: i18n.t('Não'),
    },
    {
      id: '3',
      desc: i18n.t('Indiferente'),
    },
  ]),
  filterAtributos: ref([
    {
      id: 'fsw012s39_fsw012s41',
      desc: i18n.t('Garagem / Parqueamento'),
    },
    {
      id: 'fsw012s42',
      desc: i18n.t('Piscina'),
    },
    {
      id: 'fsw012s126_fsw012s44',
      desc: i18n.t('Varanda / Terraço'),
    },
    {
      id: 'fsw012s43',
      desc: i18n.t('Quintal / Jardim'),
    },
    {
      id: 'fsw012s47',
      desc: i18n.t('Elevador'),
    },
    {
      id: 'fsw012s391',
      desc: i18n.t('Mobilidade reduzida'),
    },
    {
      id: 'fsw012s19',
      desc: i18n.t('Certificado energético'),
    },
  ]),
  filterAtributosG: ref([
    {
      id: 'fsw012s39_fsw012s41',
      desc: i18n.t('Garagem / Parqueamento'),
    },
    {
      id: 'fsw012s42',
      desc: i18n.t('Piscina'),
    },
    {
      id: 'fsw012s126_fsw012s44',
      desc: i18n.t('Varanda / Terraço'),
    },
    {
      id: 'fsw012s43',
      desc: i18n.t('Quintal / Jardim'),
    },
    {
      id: 'fsw012s47',
      desc: i18n.t('Elevador'),
    },
    {
      id: 'fsw012s391',
      desc: i18n.t('Mobilidade reduzida'),
    },
  ]),
  filterOptionsData: ref([
    {
      id: 'entrada',
      desc: i18n.t('Entrada'),
    },
    {
      id: 'alteracao',
      desc: i18n.t('Alteração'),
    },
  ]),
  filterDatas: ref([
    {
      id: '1',
      desc: i18n.t('Sempre'),
    },
    {
      id: '6',
      desc: `15 ${i18n.t('dias')}`,
    },
    {
      id: '2',
      desc: `1 ${i18n.t('mês')}`,
    },
    {
      id: '3',
      desc: `3 ${i18n.t('meses')}`,
    },
    {
      id: '4',
      desc: `6 ${i18n.t('meses')}`,
    },
    {
      id: '5',
      desc: i18n.t('Intervalo personalizado'),
    },
  ]),
  filterOptionsNewListing: ref([
    {
      id: '0',
      desc: i18n.t('Imóvel pertence a Lead'),
    },
    {
      id: '1',
      desc: i18n.t('Imóvel pertence a banco'),
    },
  ]),
  optionListingSelected: ref([
    {
      id: '0',
      desc: i18n.t('Imóvel pertence a Lead'),
    },
  ]),
  array70: ref([]),

  // Dados vista rápida da angariação
  listingSel: ref({}),
  infoConsultor: ref({}),
  infoMkt: ref({}),
  infoDataLead: ref({}),
  infoDataBank: ref({}),
  infoListing: ref({}),
  headerListing: ref({}),

  // States para guardar em localstorage
  historyKeyStorage: '',
  statesToLocalStorage: [
    'estado',
    'estadoExterna',
    'tipoImovel',
    'tipologia',
    'tipoNegocio',
    'fsw012s08_desde',
    'fsw012s08_ate',
    'fsw012s14_desde',
    'fsw012s14_ate',
    'fsw012s11',
    'fsw012s26',
    'atributo',
    'data',
    'tipoData',
    'intervaloData',
    'intervaloDataEnd',
    'fsw012s28',
    'fsw012s29',
    'fsw012s30',
    'fsw012s298',
    'fsw012s331',
    'fsw012s09',

    'currentPage',
    'searchTxt',
    'listviewOrderBy',
    'positionListview',
  ],
})

export default defaultState
