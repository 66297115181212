<template>
  <div :class="quickViewGeneral !== true ? 'email-app-details' : ''">
    <!-- Share Header -->
    <div
      v-show="quickViewGeneral !== true"
      class="email-detail-header"
    >
      <!-- Header: Left -->
      <div class="email-header-left d-flex align-items-center">
        <span class="go-back mr-1">
          <feather-icon
            :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
            size="20"
            class="align-bottom"
            @click="$emit('close-share-view')"
          />
        </span>
        <h4 class="email-subject mb-0">
          <b-row>
            <b-col
              md="12"
              class="pid"
            >
              {{ pid }}
            </b-col>
          </b-row>
        </h4>
      </div>
    </div>

    <div
      v-show="quickViewGeneral !== true"
      class="email-app-list position-relative space-bottom"
    >

      <!-- Barra pesquisa / ordenação -->
      <div
        class="app-fixed-search d-flex align-items-center"
      >
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="searchTxt"
              value=""
              :placeholder="listview_txt_default.txt_search"
            />
          </b-input-group>
        </div>

        <div
          v-if="fieldsOrder.length > 0"
          class="dropdown"
        >
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0 mr-1"
            right
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                :size="sizeIconsAction"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-for="oField in fieldsOrder"
              :key="`field-order-${oField.name}`"
              @click="listviewFilterOrderBy(oField.name)"
            >
              <feather-icon
                :icon="listviewIconOrderBy(oField.name)"
                :class="listviewIconOrderBy(oField.name, true)"
              />
              {{ oField.txt }}
            </b-dropdown-item>
          </b-dropdown>
        </div>

      </div>
      <!-- Share Details -->
      <vue-perfect-scrollbar
        ref="listviewData"
        class="email-user-list scroll-area"
        :settings="configSettingsScroll"
      >
        <listview-list-data
          v-if="listviewData.length > 0"
          ref="list"
        />
        <div
          v-if="listviewData.length === 0"
          class="no-results"
          :class="{'show': !listviewData.length}"
        >
          <h5 v-if="listviewInit === 0">
            {{ listview_txt_default.txt }}
          </h5>
          <h5 v-if="listviewInit === 1">
            {{ listview_txt_default.txt_loading }}
          </h5>
        </div>
      </vue-perfect-scrollbar>

      <b-container
        v-if="listviewData.length > 0"
        fluid
      >
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              class="mb-0 pagination-white pt-0_2 pb-0_2"
              :size="sizePaginationListviewResponsive"
            />
          </b-col>
        </b-row>
      </b-container>

      <b-overlay
        :show="$store.getters['share/loadingListview']"
        no-wrap
      />

    </div>

    <b-sidebar
      v-show="quickViewGeneral === true"
      sidebar-class="sidebar-lg"
      visible
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="$emit('close-share-view')"
    >
      <template #default="{ hide }">

        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ pid }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              :size="sizeIconsAction"
              @click="hide"
            />
          </div>
        </div>

        <vue-perfect-scrollbar
          ref="listviewData"
          class="b-sidebar-scrollarea-form"
          :settings="configSettingsScroll"
        >

          <b-container
            class="pt-2 pb-0 pl-2 pr-2"
            fluid
          >
            <listview-list-data
              v-if="listviewData.length > 0"
              ref="list"
              :quick-view-general="true"
            />
          </b-container>
        </vue-perfect-scrollbar>

        <div
          class="d-flex p-1 justify-content-center"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click.stop.prevent="hide"
          >
            {{ $t('Fechar') }}
          </b-button>
        </div>

        <b-overlay
          :show="$store.getters['share/loadingListview']"
          no-wrap
        />

      </template>
    </b-sidebar>

  </div>
</template>

<script>
import {
  BRow, BCol, BFormInput, BInputGroup, BInputGroupPrepend, BDropdown, BDropdownItem, BContainer, BPagination, BOverlay, VBTooltip,
  BSidebar, BButton,
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'
import { Dialog } from '@capacitor/dialog'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import useCapacitor from '@core-custom/utils/capacitor'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { configSettingsScroll } from '@core-custom/utils/ui'
import ListviewListData from './listData.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BContainer,
    BPagination,
    BOverlay,
    BSidebar,
    BButton,
    ListviewListData,
    VuePerfectScrollbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [sizeIconsAction],
  props: {
    quickViewGeneral: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('share', ['listviewData', 'listviewInit', 'listview_txt_default', 'listviewOrderBy', 'fieldsOrder', 'pid', 'idListing', 'totalRows', 'perPage']),
    ...mapGetters('listings', ['pageSel']),
    searchTxt: {
      get() { return this.$store.getters['share/searchTxt'] },
      set(newValue) {
        this.$store.dispatch('share/setSearchTxt', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
    currentPage: {
      get() { return this.$store.getters['share/currentPage'] },
      set(newValue) {
        this.$store.dispatch('share/setCurrentPage', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
    sizePaginationListviewResponsive() {
      const currentBreakPoint = this.$store.getters['app/currentBreakPoint']
      return ((currentBreakPoint === 'xs') ? 'lg' : 'md')
    },
  },
  async created() {
    try {
      await store.dispatch('share/setPageSel', { pageSel: this.$route.name }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    } catch (err) {
      //
    }
  },
  beforeDestroy() {
    if (this.$refs.listviewScroll) {
      this.$refs.listviewScroll.destroy()
    }
  },
  methods: {
    async showMsgErrorRequest(error) {
      if (useCapacitor.isNative() === true) {
        Dialog.alert({
          title: this.$t('Alerta'),
          message: error.message,
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('Alerta'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.message,
          },
        })
      }
    },
    listviewFilterOrderBy(field) {
      this.$store.dispatch('share/listviewFilterOrderBy', field).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    listviewIconOrderBy(filterField, renderClass) {
      let returnIcon = 'ArrowUpIcon'
      let returnClass = 'opacity-0'

      if (this.listviewOrderBy.column === filterField) {
        if (this.listviewOrderBy.sort === 'asc') {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowUpIcon'
        } else {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowDownIcon'
        }
      }

      if (renderClass === true) {
        return returnClass
      }

      return returnIcon
    },
  },
  setup() {
    return {
      configSettingsScroll,
    }
  },
}
</script>

<style lang="scss" scoped>
.email-application .email-app-details{
  height: 100%!important;
  z-index: 4!important;
}

.ecommerce-application .email-app-details .app-fixed-search {
  padding: 0 0 0 0!important;
}

.ecommerce-application .email-app-details .app-fixed-search input {
  margin-top: 0.4rem;
}

.email-user-list {
  height: calc( 100% - calc(11.4rem) )!important;
}

.capacitor{
  .email-app-details
  .email-user-list ::v-deep{
    .email-media-list li {
      animation:none!important;
      animation-fill-mode:none!important;
    }
  }
}
</style>
