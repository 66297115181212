<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div :class="{'email-app-menu': true, 'sws-not-button-create': showButtonsActions===false }">
          <div
            v-if="showListingQuickView === false && showButtonsActions === true"
            class="form-group-compose text-center compose-btn"
          >
            <b-button
              v-if="showButtonNewAng === true && checkShowButton===true && currentBreakPointMobile===false && showListingQuickView === false"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              @click="createNew($event);$emit('close-left-sidebar');"
            >
              {{ $t('Nova angariação') }}
            </b-button>
            <b-button
              v-if="(![5, 8, 9, 10, 13].includes(Number(currentUser.id_role)) && ((typeof currentUser.extras !== 'undefined' && currentUser.extras.basic === 1)
                || (typeof currentUser.extras !== 'undefined' && typeof currentUser.extras.perm !== 'undefined' && Object.values(currentUser.extras.perm).includes('add_ang_C'))))
                && checkShowButton===true && currentBreakPointMobile===false && showListingQuickView === false"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              @click="createNewPre($event);$emit('close-left-sidebar');"
            >
              {{ $t('Nova pré-angariação') }}
            </b-button>
            <b-button
              v-if="currentBreakPointMobile===true && showListingQuickView === false"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              @click="$emit('close-left-sidebar');"
            >
              {{ $t('Pesquisar') }}
            </b-button>
          </div>

          <vue-perfect-scrollbar
            v-if="dataFiltersListview.load === true"
            ref="filterScroll"
            class="sidebar-menu-list scroll-area"
            :settings="configSettingsScroll"
          >
            <div v-show="showListingQuickView === false">

              <!-- Estado -->
              <h6 class="section-label mt-1 mb-1 px-2">
                {{ $t('Estados') }}
              </h6>
              <b-form-checkbox-group
                v-if="pageSel !== 'listings-listview-externas'"
                id="checkbox-group-2"
                v-model="selectedDataFiltersListview.estado"
                name="flavour-2"
              >
                <b-form-checkbox
                  :value="0"
                  class="mb-1 ml-2"
                  @change="selectEstado(0)"
                >
                  {{ $t('Todos') }}
                </b-form-checkbox>
                <div
                  v-for="(row, index) in dataFiltersListview.estados"
                  :key="`status-listing-${index}`"
                >
                  <b-form-checkbox
                    :value="index"
                    class="mb-1 ml-2"
                    @change="selectEstado(index)"
                  >
                    {{ row }}
                  </b-form-checkbox>
                </div>
              </b-form-checkbox-group>
              <b-form-checkbox-group
                v-if="pageSel === 'listings-listview-externas'"
                id="checkbox-group-2"
                v-model="selectedDataFiltersListview.estadoExterna"
                name="flavour-2"
              >
                <div
                  v-for="row in dataFiltersListview.estadosExternas"
                  :key="`status-listing-${row.id}`"
                >
                  <b-form-checkbox
                    :value="row.id"
                    class="mb-1 ml-2"
                    @change="selectEstadoExterna(row.id)"
                  >
                    {{ row.desc }}
                  </b-form-checkbox>
                </div>
              </b-form-checkbox-group>

              <!-- Tipo -->
              <h6 class="section-label mt-2 mb-1 px-2">
                {{ $t('Tipo') }}
              </h6>
              <b-form-radio
                v-model="selectedDataFiltersListview.tipoImovel"
                name="some-radios"
                value="0"
                class="mb-1 ml-2"
                @change="selectTipoImovel(0)"
              >
                {{ $t('Indiferente') }}
              </b-form-radio>
              <div
                v-for="row in dataFiltersListview.tipoImoveis"
                :key="`tipo-${row.sw087s01}`"
              >
                <b-form-radio
                  v-model="selectedDataFiltersListview.tipoImovel"
                  :value="row.sw087s01"
                  class="mb-1 ml-2"
                  @change="selectTipoImovel(row.sw087s01)"
                >
                  {{ row.sw087s02 }}
                </b-form-radio>
              </div>

              <!-- Imóvel -->
              <h6 class="section-label mt-3 mb-1 px-2">
                {{ $t('Imóvel') }}
              </h6>
              <b-form-checkbox-group
                id="checkbox-group-2"
                v-model="selectedDataFiltersListview.tipologia"
                name="flavour-2"
              >
                <b-form-checkbox
                  value="0"
                  class="mb-1 ml-2"
                  @change="selectTipologia(0)"
                >
                  {{ $t('Todos') }}
                </b-form-checkbox>
                <div
                  v-for="row in dataFiltersListview.tipologias"
                  :key="`tipologia-${row.id}`"
                >
                  <b-form-checkbox
                    :value="row.id"
                    class="mb-1 ml-2"
                    @change="selectTipologia(row.id)"
                  >
                    {{ row.desc }}
                  </b-form-checkbox>
                </div>
              </b-form-checkbox-group>

              <!-- Negócio -->
              <h6 class="section-label mt-2 mb-1 px-2">
                {{ $t('Negócio') }}
              </h6>
              <b-form-checkbox
                v-model="selectedDataFiltersListview.tipoNegocio"
                value="0"
                class="mb-1 ml-2"
                @change="selectTipoNegocio(0)"
              >
                {{ $t('Todos') }}
              </b-form-checkbox>
              <div
                v-for="row in dataFiltersListview.tipoNegocios"
                :key="`tipo-negocio-${row.sw007s01}`"
              >
                <b-form-checkbox
                  v-model="selectedDataFiltersListview.tipoNegocio"
                  :value="row.sw007s01"
                  class="mb-1 ml-2"
                  @change="selectTipoNegocio(row.sw007s01)"
                >
                  {{ row.sw007s02 }}
                </b-form-checkbox>
              </div>

              <listview-search-advance
                v-show="buttonSearch === 'primary'"
                ref="searchAdvance"
              />

              <div
                class="form-group-compose text-center compose-btn"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  :variant="buttonSearch"
                  block
                  class="mr-1"
                  @click.stop.prevent="openSearchAdvance(buttonSearch);"
                >
                  <feather-icon
                    icon="SlidersIcon"
                    class="mr-50"
                  />
                  <span
                    v-if="buttonSearch !== 'primary'"
                    class="align-middle"
                  >
                    {{ $t('Pesquisa avançada') }}
                  </span>
                  <span
                    v-if="buttonSearch === 'primary'"
                    class="align-middle"
                  >
                    {{ $t('Pesquisa simples') }}
                  </span>
                </b-button>
                <b-button
                  v-if="buttonSearch === 'primary'"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-secondary"
                  block
                  class="mr-1"
                  @click="resetFilterSearchAdvance()"
                >
                  <span class="align-middle">{{ $t('Limpar') }}</span>
                </b-button>
              </div>
            </div>
            <div v-show="showListingQuickView === true">
              <action-listing
                :set-show-user-share-details="setShowUserShareDetails"
                :show-quick-view="true"
                :open-left-sidebar="openLeftSidebar"
              />
            </div>

          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton, BFormCheckbox, BFormRadio, BFormCheckboxGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { mapGetters, mapActions } from 'vuex'
import { configSettingsScroll, currentBreakPointMobile } from '@core-custom/utils/ui'
import ListviewSearchAdvance from './searchAdvance.vue'
import ActionListing from './options.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormRadio,
    VuePerfectScrollbar,
    ListviewSearchAdvance,
    ActionListing,
  },
  props: {
    createNew: {
      type: Function,
      required: true,
    },
    createNewPre: {
      type: Function,
      required: true,
    },
    showMsgErrorRequest: {
      type: Function,
      required: true,
    },
    setShowUserShareDetails: {
      type: Function,
      required: true,
    },
    openLeftSidebar: {
      type: Function,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      showButtonNewAng: false,
    }
  },
  computed: {
    ...mapGetters('listings', ['dataFiltersListview', 'selectedDataFiltersListview', 'buttonSearch', 'pageSel', 'showListingQuickView']),
    ...mapGetters('auth', ['currentUser']),
    checkShowButton() {
      if ((this.pageSel === 'listings-myShares') || (this.pageSel === 'listings-shares') || (this.pageSel === 'listings-shared') || (this.pageSel === 'listings-shared')) {
        return false
      }

      return true
    },
    showButtonsActions() {
      if (this.showButtonNewAng === true && this.checkShowButton === true && this.currentBreakPointMobile === false && this.showListingQuickView === false) {
        return true
      }

      if (![5, 9, 10, 13].includes(Number(this.currentUser.id_role)) && this.checkShowButton === true && this.currentBreakPointMobile === false && this.showListingQuickView === false) {
        return true
      }

      if (this.currentBreakPointMobile === true && this.showListingQuickView === false) {
        return true
      }

      return false
    },
  },
  methods: {
    ...mapActions('listings', ['selectEstado', 'selectEstadoExterna', 'selectTipoNegocio', 'selectTipoImovel', 'selectTipologia']),
    openSearchAdvance(newValue) {
      if (newValue === 'outline-primary') {
        setTimeout(() => {
          const container = this.$el.querySelector('.scroll-area')
          container.scrollTop = 900
        }, 0)
      }

      this.$store.dispatch('listings/openSearchAdvance', newValue).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    resetFilterSearchAdvance() {
      this.$refs.searchAdvance.$refs.location.clearForm()
      this.$store.dispatch('listings/resetFilterSearchAdvance').catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
  },
  setup() {
    return {
      configSettingsScroll,
      currentBreakPointMobile,
    }
  },
}
</script>

<style lang="scss" scoped>
.email-application .content-area-wrapper .sidebar .email-app-sidebar .email-app-menu.sws-not-button-create .sidebar-menu-list{
  height: calc(100%)!important;
}

</style>
