import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'

const defaultState = () => ({
  // Variaveis gerais da listagem
  listviewInit: ref(1),
  listviewListingsInit: ref(1),
  listviewData: ref([]),
  listviewListings: ref([]),
  loadingListview: ref(true),
  loadingListviewListings: ref(true),
  perPage: ref(25),
  listingsPerPage: ref(25),
  totalRows: ref(0),
  totalRegsListings: ref(0),
  listingsTotalRows: ref(0),
  currentPage: ref(1),
  listingsCurrentPage: ref(1),
  perPageOptions: ref([10, 25, 50, 100]),
  searchTxt: ref(''),
  searchListingsTxt: ref(''),
  totalRegs: ref(0),
  showUserShareDetails: ref(false),
  showListingShareDetails: ref(false),
  pid: ref(''),
  idListing: ref(0),
  agent: ref(''),
  idAgent: ref(0),
  pageSel: ref(''),
  listviewOrderBy: ref({
    column: '0',
    sort: 'asc',
  }),
  listviewListingsOrderBy: ref({
    column: '0',
    sort: 'desc',
  }),
  listview_txt_default: i18n.t('Nenhum resultado disponível'),
  listiview_txt_not_find: i18n.t('Não encontrou nenhum resultado'),
  listiview_txt_search: i18n.t('Nome, telemóvel, email ou link'),
  listiviewlistings_txt_search: i18n.t('ZMID ou link'),
  listview_txt_loading: i18n.t('A carregar registos ...'),
  aFieldsOrder: [
    {
      name: '0',
      txt: i18n.t('Ordenar por nome'),
    },
    {
      name: '1',
      txt: i18n.t('Ordenar por cargo'),
    },
    {
      name: '2',
      txt: i18n.t('Ordenar por HUB'),
    },
    {
      name: '3',
      txt: i18n.t('Ordenar por email'),
    },
  ],
  aFieldsOrderAgents: [
    {
      name: '0',
      txt: i18n.t('Ordenar por nome'),
    },
    {
      name: '1',
      txt: i18n.t('Ordenar por HUB'),
    },
    {
      name: '2',
      txt: i18n.t('Ordenar por morada'),
    },
    {
      name: '3',
      txt: i18n.t('Ordenar por email'),
    },
  ],
  sortByOptions: [
    {
      name: '0',
      txt: i18n.t('Ordenar por estado'),
    },
    {
      name: '2',
      txt: i18n.t('Ordenar por ZMID'),
    },
    {
      name: '3',
      txt: i18n.t('Ordenar por local'),
    },
    {
      name: '4',
      txt: i18n.t('Ordenar por nome'),
    },
  ],
})

export default defaultState
