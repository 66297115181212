<template>
  <div>
    <b-row>
      <b-col :md="modeForm === true ? '12' : '6'">
        <swiper
          v-if="typeof dataQuickView.headerListing.images !== 'undefined' && dataQuickView.headerListing.images.length > 0"
          class="swiper mb-2"
          :options="swiperOptions"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :style="{'--swiper-navigation-color': '#000','--swiper-pagination-color': '#fff'}"
        >
          <swiper-slide
            v-for="(row, index) in dataQuickView.headerListing.images"
            :key="`image-${index}`"
          >
            <div class="swiper-zoom-container">
              <b-img
                :src="row + '?v=' + new Date().getTime()"
                class="w-100 image-swiper"
                fluid
              />
            </div>
          </swiper-slide>
          <div
            slot="pagination"
            class="swiper-pagination swiper-pagination-white"
          />
        </swiper>
      </b-col>
    </b-row>

    <b-row>
      <b-col :md="modeForm === true ? '12' : '6'">
        <div
          class="item-wrapper d-flex"
          :style="[{ 'justify-content': 'space-between', 'align-items': 'baseline' }]"
        >
          <div
            class="item-rating"
          >
            <b-button
              v-if="(showRating===true) && dataQuickView.headerListing.rating.percentagem !== null && dataQuickView.headerListing.rating.percentagem !== ''"
              :variant="(dataQuickView.headerListing.rating.btn_color === 'red' ? 'danger' : dataQuickView.headerListing.rating.btn_color === 'orange' ? 'warning' : 'success')"
              class="btn-sm"
              @click="getModalRatingListing(baseEncode(dataQuickView.infoListing.sw012s01), null)"
            >
              <feather-icon
                icon="FrownIcon"
                class="mr-50"
              />
              <span class="align-middle">{{ dataQuickView.headerListing.rating.percentagem }}%</span>
            </b-button>
          </div>
          <div v-if="(dataQuickView.infoListing.sw012s14 !== null && dataQuickView.infoListing.sw012s14 !== '') || (dataQuickView.infoListing.sw012s69 !== null && dataQuickView.infoListing.sw012s69 !== '')">
            <h6
              v-if="dataQuickView.infoListing.sw012s14 !== null && dataQuickView.infoListing.sw012s14 !== ''"
              class="item-price"
            >
              {{ dataQuickView.infoListing.currencyL }} {{ formatPrice(dataQuickView.infoListing.sw012s14) }} {{ dataQuickView.infoListing.currencyR }}
            </h6>
            <h6
              v-if="dataQuickView.infoListing.sw012s69 !== null && dataQuickView.infoListing.sw012s69 !== ''"
              class="item-price"
            >
              {{ dataQuickView.infoListing.currencyL }} {{ formatPrice(dataQuickView.infoListing.sw012s69) }} {{ dataQuickView.infoListing.currencyR }}
            </h6>
          </div>
          <div v-else>
            <h6 class="item-price">
              {{ '\xa0' }}
            </h6>
          </div>
        </div>
        <h6
          v-if="typeof dataQuickView.infoListing.header_detail !== 'undefined' && dataQuickView.infoListing.header_detail !== null"
          class="item-name mt-75 mb-75"
        >
          {{ getTxt(dataQuickView.infoListing.header_detail.tipologia) }}
        </h6>
        <b-card-text
          v-if="typeof dataQuickView.headerListing.optionsAction !== 'undefined' && dataQuickView.headerListing.optionsAction !== null"
          class="item-description mt-0 mb-0"
        >
          {{ getTxt(dataQuickView.headerListing.optionsAction.morada) }}
        </b-card-text>
        <b-card-text class="item-description mt-0 mb-0">
          <b
            v-if="((Number(baseDecode(currentUser.id)) === Number(dataQuickView.infoListing.sw012s60) || Number(baseDecode(currentUser.id)) === Number(dataQuickView.infoListing.sw012s04))
              || (Number(dataQuickView.infoListing.sw012s72) === Number(currentUser.idHUB) && [5, 9, 10, 13].includes(Number(currentUser.id_role))))"
          >
            {{ getTxt(dataQuickView.infoListing.sw012s03) }}
          </b>
          <b v-else>
            {{ '\xa0' }}
          </b>
        </b-card-text>
        <b-card-text class="item-description mt-1 mb-0">
          <b-media v-if="typeof dataQuickView.infoDataLead !== 'undefined' && typeof dataQuickView.infoDataLead.sw025s01 !== 'undefined' && dataQuickView.infoDataLead.sw025s01 !== ''">
            <h6 class="mb-0">
              {{ dataQuickView.infoDataLead.sw025s03 }} {{ dataQuickView.infoDataLead.sw025s04 }}
            </h6>
            <p
              v-if="typeof dataQuickView.infoDataLead.sw025s06 !== 'undefined' && dataQuickView.infoDataLead.sw025s06 !== null"
              class="mb-0"
            >
              <template v-if="dataQuickView.infoDataLead.sw025s06">
                <b-link :href="`mailto:${dataQuickView.infoDataLead.sw025s06}`">
                  {{ dataQuickView.infoDataLead.sw025s06 }}
                </b-link>
              </template>
              <template v-else>
                -----
              </template>
            </p>
            <p
              v-if="typeof dataQuickView.infoDataLead.sw025s05 !== 'undefined' && dataQuickView.infoDataLead.sw025s05 !== null"
              class="mb-0"
            >
              <template v-if="dataQuickView.infoDataLead.sw025s05">
                <b-link :href="`tel:${dataQuickView.infoDataLead.sw025s05.trim()}`">
                  {{ dataQuickView.infoDataLead.sw025s05 }}
                </b-link>
              </template>
              <template v-else>
                -----
              </template>
            </p>
          </b-media>
          <b-media v-if="typeof dataQuickView.infoDataBank !== 'undefined' && typeof dataQuickView.infoDataBank.sw129s01 !== 'undefined' && dataQuickView.infoDataBank.sw129s01 !== ''">
            <h6 class="mb-0">
              {{ dataQuickView.infoDataBank.sw129s02 }}
            </h6>
            <p
              v-if="typeof dataQuickView.infoDataLead.sw129s13 !== 'undefined' && dataQuickView.infoDataLead.sw025s06 !== null"
              class="mb-0"
            >
              {{ dataQuickView.infoDataBank.sw129s13 }}
            </p>
          </b-media>
          <b-media v-if="typeof dataQuickView.infoConsultor !== 'undefined' && typeof dataQuickView.infoConsultor.sw001s01 !== 'undefined' && dataQuickView.infoConsultor.sw001s01 !== ''">
            <template #aside>
              <b-avatar :src="`${dataQuickView.infoConsultor.imagem + '?v=' + new Date().getTime()}`" />
            </template>
            <h6 class="mb-0">
              {{ dataQuickView.infoConsultor.nomeUser }} - {{ dataQuickView.infoMkt.localHUB }}
            </h6>
            <p class="mb-0">
              <template v-if="dataQuickView.infoConsultor.sw001s03">
                <b-link :href="`mailto:${dataQuickView.infoConsultor.sw001s03}`">
                  {{ dataQuickView.infoConsultor.sw001s03 }}
                </b-link>
              </template>
            </p>
            <p class="mb-0">
              <template v-if="dataQuickView.infoConsultor.contactoUser">
                <b-link :href="`tel:${dataQuickView.infoConsultor.contactoUser.trim()}`">
                  {{ dataQuickView.infoConsultor.contactoUser }}
                </b-link>
              </template>
            </p>
          </b-media>
        </b-card-text>
        <div class="mt-75">
          <b-button
            v-if="typeof dataQuickView.headerListing.estado !== 'undefined' && dataQuickView.headerListing.estado !== null"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="outline-secondary"
            class="btn-sm mr-25"
            :style="[{ cursor: 'default' }, getColorByStatus(dataQuickView.infoListing.sw012s64)]"
          >
            {{ getTxt(dataQuickView.headerListing.estado.sw018s02) }}
          </b-button>
        </div>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col :md="modeForm === true ? '12' : '6'">
        <b-form-group
          :label="$t('Atributos')"
        >
          <p
            v-if="Number(dataQuickView.infoListing.sw012s22) === 1"
            class="text-primary mb-0"
          >
            {{ $t('Condomínio Fechado') }}
          </p>

          <p
            v-if="Number(dataQuickView.infoListing.sw012s23) === 1"
            class="text-primary mb-0"
          >
            {{ $t('Imóvel com penhora') }}
          </p>

          <p
            v-if="Number(dataQuickView.infoListing.sw012s24) === 1"
            class="text-primary mb-0"
          >
            {{ $t('Imóvel de banco') }}
          </p>

          <p
            v-if="Number(dataQuickView.infoListing.sw012s310) === 1"
            class="text-primary mb-0"
          >
            {{ $t('Imóvel de herança') }}
          </p>

          <p
            v-if="countryApp.toUpperCase() === 'PT' && Number(dataQuickView.infoListing.sw012s550) === 1"
            class="text-primary mb-0"
          >
            {{ $t('Imóvel Verificado by Zome') }}
          </p>

          <p class="text-primary mb-0">
            {{ $t('Assoalhadas') }}: {{ getTxt(dataQuickView.infoListing.sw012s37) }}
          </p>

          <p class="text-primary mb-0">
            {{ $t('Suite(s)') }}: {{ getTxt(dataQuickView.infoListing.sw012s143) }}
          </p>

          <p class="text-primary mb-0">
            {{ $t('WCs') }}: {{ getTxt(dataQuickView.infoListing.sw012s13) }}
          </p>

          <p class="text-primary mb-0">
            {{ $t('Garagem') }}:
            <span v-if="dataQuickView.infoListing.sw012s39 === null || dataQuickView.infoListing.sw012s39 === '' || Number(dataQuickView.infoListing.sw012s39) === 1"> {{ $t('Não') }}</span>
            <span v-if="Number(dataQuickView.infoListing.sw012s39) === 2"> {{ $t('aberta') }}</span>
            <span v-if="Number(dataQuickView.infoListing.sw012s39) === 3"> {{ $t('fechada') }}</span>
            <span v-if="!['', 0, 1].includes(Number(dataQuickView.infoListing.sw012s39)) && dataQuickView.infoListing.sw012s40 !== null && dataQuickView.infoListing.sw012s40 !== ''"> {{ dataQuickView.infoListing.sw012s40 }} {{ $t('lugares') }}</span>
          </p>

          <p class="text-primary mb-0">
            {{ $t('Parqueamento') }}:
            <span v-if="Number(dataQuickView.infoListing.sw012s41) === 0"> {{ $t('Não') }}</span>
            <span v-else> {{ dataQuickView.infoListing.sw012s41 }}
              <span v-if="Number(dataQuickView.infoListing.sw012s41) === 1"> {{ $t('lugar') }}</span>
              <span v-else> {{ $t('lugares') }}</span>
            </span>
          </p>

          <p class="text-primary mb-0">
            {{ $t('Elevador') }}:
            <span v-if="Number(dataQuickView.infoListing.sw012s47) === 1"> {{ $t('Sim') }}</span>
            <span v-else> {{ $t('Não') }}</span>
          </p>

          <app-collapse
            v-if="typeof dataQuickView.headerListing.atributos !== 'undefined' && dataQuickView.headerListing.atributos.length > 0"
            accordion
            type="defualt"
            class="mt-1"
            :style="[{ 'margin-left': '-14px' }]"
          >
            <app-collapse-item
              :title="$t('Ver todos atributos')"
            >
              <p
                v-for="(row, index) in dataQuickView.headerListing.atributos"
                :key="`attr-${index}`"
                class="text-primary mb-0"
              >
                {{ row }}
              </p>
            </app-collapse-item>
          </app-collapse>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col :md="modeForm === true ? '12' : '6'">
        <app-collapse
          accordion
          type="defualt"
          :style="[{ 'margin-left': '-14px' }]"
        >
          <app-collapse-item
            :title="$t('Descritivo')"
          >
            <!-- eslint-disable vue/no-v-html -->
            <p
              class="text-primary mb-0"
              v-html="getTxt(dataQuickView.infoListing.sw012s35)"
            />
            <!--eslint-enable-->
          </app-collapse-item>
        </app-collapse>
      </b-col>
    </b-row>

    <b-row
      v-if="dataQuickView.infoListing.sw012s33 !== null && dataQuickView.infoListing.sw012s33 !== ''"
      class="mt-2"
    >
      <b-col :md="modeForm === true ? '12' : '6'">
        <google-map
          ref="googleMaps"
          :center="mapsLatLong"
          :zoom="15"
          map-type-id="roadmap"
          class="vue-google-maps"
          :options="{
            keyboardShortcuts: false,
            gestureHandling:'cooperative',
            rotateControl: false,
            tilt:0,
          }"
        >
          <google-map-marker
            :position="mapsLatLong"
            :clickable="false"
            :draggable="false"
          />
        </google-map>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import store from '@/store'
import {
  VBTooltip, BCol, BRow, BAvatar, BFormGroup, BCardText, BMedia, BButton, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import Vue from 'vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { mapGetters } from 'vuex'
import 'swiper/css/swiper.css'
import { formatPrice } from '@core-custom/utils/ui'

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_API_GOOGLE_MAPS,
    region: store.getters['auth/countryApp'],
    language: store.getters['auth/countryApp'],
  },
  installComponents: false,
})

export default {
  components: {
    BCol,
    BRow,
    BAvatar,
    BFormGroup,
    BCardText,
    BMedia,
    BButton,
    BLink,
    BImg,
    AppCollapse,
    AppCollapseItem,
    Swiper,
    SwiperSlide,
    'google-map': VueGoogleMaps.Map,
    'google-map-marker': VueGoogleMaps.Marker,

  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [sizeIconsAction],
  props: {
    getModalRatingListing: {
      type: Function,
      required: true,
    },
    modeForm: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      swiperOptions: {
        zoom: true,
        dynamicBullets: true,
        pagination: {
          el: '.swiper-pagination',
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      formatPrice,
    }
  },
  computed: {
    ...mapGetters('listings', ['dataQuickView']),
    ...mapGetters('auth', ['currentUser', 'countryApp']),
    mapsLatLong() {
      try {
        const [sLat, sLong] = this.dataQuickView.infoListing.sw012s33.split('###')

        return {
          lat: Number(sLat),
          lng: Number(sLong),
        }
      } catch (err) {
        return {
          lat: Number(0),
          lng: Number(0),
        }
      }
    },
    showRating() {
      try {
        if ((this.dataQuickView.headerListing !== null) && (this.dataQuickView.headerListing !== undefined)) {
          if ('rating' in this.dataQuickView.headerListing
          && ([1, 8, 14].includes(Number(this.currentUser.id_role))
            || (([3, 5, 6, 9, 10, 11, 12, 13].includes(Number(this.currentUser.id_role)) || Number(atob(this.currentUser.id)) === Number(this.dataQuickView.headerListing.sw012s04) || Number(this.currentUser.extras.idLeader) === Number(this.dataQuickView.headerListing.sw012s04))
            && Number(this.currentUser.idHUB) === Number(this.dataQuickView.infoConsultor.sw001s12)))) {
            return true
          }
        }
      } catch (err) {
        //
      }

      return false
    },
  },
  methods: {
    baseDecode(val) {
      if (typeof val !== 'undefined') {
        return atob(val)
      }
      return ''
    },
    baseEncode(val) {
      if (typeof val !== 'undefined') {
        return btoa(val)
      }
      return ''
    },
    getTxt(value) {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          let txt = ''

          value.forEach(o => {
            if ((o !== null) && (o !== undefined)) {
              txt += `${o}, `
            }
          })

          return txt.slice(0, -2) || '-----'
        }
        return '-----'
      }

      if ((value !== null) && (value !== undefined) && (value !== '')) {
        return value
      }
      return '-----'
    },
    getColorByStatus(id) {
      if (Number(id) === 1) {
        return { 'border-color': '#1BBC9B!important', color: '#1BBC9B' }
      }
      if (Number(id) === 2) {
        return { 'border-color': '#9A12B3!important', color: '#9A12B3' }
      }
      if (Number(id) === 3) {
        return { 'border-color': '#C49F47!important', color: '#C49F47' }
      }
      if (Number(id) === 4) {
        return { 'border-color': '#D91E18!important', color: '#D91E18' }
      }
      if (Number(id) === 5) {
        return { 'border-color': '#F3C200!important', color: '#F3C200' }
      }
      if (Number(id) === 6) {
        return { 'border-color': '#E87E04!important', color: '#E87E04' }
      }
      if (Number(id) === 7) {
        return { 'border-color': '#999999!important', color: '#999999' }
      }
      if (Number(id) === 9) {
        return { 'border-color': '#000000!important', color: '#000000' }
      }

      return {}
    },
  },
}
</script>

<style lang="scss">
.swiper-container {
  width: 100%;
  height: 100%;
}
</style>

<style lang="scss" scoped>
.vue-google-maps{
  width: 100%;
  height: 500px;
}
</style>
