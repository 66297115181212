<template>
  <div>
    <b-sidebar
      sidebar-class="sidebar-lg"
      visible
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="closeModalOptions(false)"
    >
      <template #default="{ hide }">

        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            {{ pid }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              :size="sizeIconsAction"
              @click="hide"
            />
          </div>
        </div>

        <b-container
          class="pt-2 pb-0 pl-2 pr-2"
          fluid
        >
          <options-listing
            :set-show-user-share-details="setShowUserShareDetails"
            :open-view-market-research="openViewMarketResearch"
          />
        </b-container>

      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BContainer,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import OptionsListing from './options.vue'

export default {
  components: {
    BSidebar,
    BContainer,
    OptionsListing,
  },
  directives: {
    Ripple,
  },
  mixins: [sizeIconsAction],
  props: {
    setShowUserShareDetails: {
      type: Function,
      required: true,
    },
    openViewMarketResearch: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapGetters('listings', ['pid']),
  },
  methods: {
    closeModalOptions(val) {
      this.$store.dispatch('listings/setShowOptions', val).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
  },
}
</script>
