<template>
  <div>
    <div
      v-if="typeof optionsListing !== 'undefined' && optionsListing.actionViewAllRede === '' && optionsListing.nameSectionList === 'externas'"
      class="form-group-compose text-center compose-btn"
    >
      <div class="d-flex mt-2">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
          :to="optionsListing.showVersionPending !== '' ? { name: 'listings-view-detail-pending', params: { id: idListing, status: 'pending' } } : { name: 'listings-view-detail', params: { id: idListing } }"
        >
          <span class="align-middle">{{ $t('Ver') }}</span>
        </b-button>
      </div>
      <div
        v-if="optionsListing.nameSectionList !== 'externas'"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
        >
          <span class="align-middle">{{ $t('Iniciar processo de venda') }}</span>
        </b-button>
      </div>
      <div
        v-if="Number(optionsListing.estado) === 9 && optionsListing.userCanCreateAction === true && optionsListing.userCanEditAction === true"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
          :to="{ name: 'listings-edit', params: { id: idListing } }"
        >
          <span class="align-middle">{{ $t('Editar') }}</span>
        </b-button>
      </div>
      <div
        v-if="optionsListing.userCanCreateAction === true && optionsListing.userHUBIsHM === true"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
          @click="deleteListing('')"
        >
          <span class="align-middle">{{ $t('Eliminar') }}</span>
        </b-button>
      </div>
      <div
        v-if="optionsListing.delete !== ''"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
          @click="restaurarListing"
        >
          <span class="align-middle">{{ $t('Restaurar') }}</span>
        </b-button>
      </div>
      <div
        v-if="optionsListing.location !== ''"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          :href="'https://www.google.pt/maps/place/' + optionsListing.location"
          target="_blank"
          variant="outline-secondary"
          block
        >
          <span class="align-middle">{{ $t('Ver mapa') }}</span>
        </b-button>
      </div>
    </div>

    <div
      v-if="typeof optionsListing !== 'undefined' && optionsListing.actionViewAllRede === '' && optionsListing.rascunho !== ''"
      class="form-group-compose text-center compose-btn"
    >
      <div
        v-if="optionsListing.delete !== '' && [2].includes(Number(optionsListing.delete))"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
          :to="{ name: 'listings-view-detail', params: { id: idListing } }"
        >
          <span class="align-middle">{{ $t('Vista completa') }}</span>
        </b-button>
      </div>
      <div
        v-if="showButtonSubmit === true && (optionsListing.delete === '' || ![2].includes(Number(optionsListing.delete))) && optionsListing.userCanExecuteAction === true && optionsListing.userCanSubmitAng === true"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
          :to="{ name: 'listings-edit', params: { id: idListing } }"
        >
          <span class="align-middle">{{ $t('Submeter') }}</span>
        </b-button>
      </div>
      <div
        v-if="(optionsListing.nameSectionList !== 'externas') && (optionsListing.delete === '' || ![2].includes(Number(optionsListing.delete))) && optionsListing.userCanExecuteAction === true"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
          :to="optionsListing.showVersionPending !== '' ? { name: 'listings-view-detail-pending', params: { id: idListing, status: 'pending' } } : { name: 'listings-view-detail', params: { id: idListing } }"
        >
          <span class="align-middle">{{ $t('Vista completa') }}</span>
        </b-button>
      </div>
      <div
        v-if="(optionsListing.nameSectionList !== 'externas') && (optionsListing.delete === '' || ![2].includes(Number(optionsListing.delete))) && optionsListing.userCanExecuteAction === true && appConfig.bUseEstudoMercado === true && optionsListing.sOptionHaveEM === true && showButtonEM === true"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
        >
          <span class="align-middle">{{ $t('Abrir estudo de mercado') }}</span>
        </b-button>
      </div>
      <div
        v-if="(optionsListing.nameSectionList !== 'externas') && (optionsListing.delete === '' || ![2].includes(Number(optionsListing.delete))) && optionsListing.userCanExecuteAction === true && appConfig.bUseEstudoMercado === true && optionsListing.sOptionHaveEM === false && showButtonEM === true"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
        >
          <span class="align-middle">{{ $t('Iniciar estudo de mercado') }}</span>
        </b-button>
      </div>
      <div
        v-if="(optionsListing.nameSectionList !== 'externas') && optionsListing.delete === '' && optionsListing.userCanExecuteAction === true && appConfig.bUseEstudoMercadoAnalise === true"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
          @click.stop.prevent="openViewMarketResearch(idListing)"
        >
          <span class="align-middle">{{ $t('Iniciar estudo de mercado') }}</span>
        </b-button>
      </div>
      <div
        v-if="Number(optionsListing.estado) === 9 && (optionsListing.delete === '' || ![2].includes(Number(optionsListing.delete))) && optionsListing.userCanExecuteAction === true && optionsListing.userCanEditAction === true"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
          :to="{ name: 'listings-edit', params: { id: idListing } }"
        >
          <span class="align-middle">{{ $t('Editar') }}</span>
        </b-button>
      </div>
      <div
        v-if="Number(optionsListing.estado) === 9 && (optionsListing.delete === '' || ![2].includes(Number(optionsListing.delete))) && optionsListing.userCanExecuteAction === true && optionsListing.nameSectionList !== 'externas' && optionsListing.userCanDuplAction === true"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
          @click="duplicateListing"
        >
          <span class="align-middle">{{ $t('Duplicar') }}</span>
        </b-button>
      </div>
      <div
        v-if="(optionsListing.delete === '' || ![2].includes(Number(optionsListing.delete))) && optionsListing.userCanExecuteAction === true && optionsListing.nameSectionList !== 'externas' && optionsListing.userCanEditAction === true"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
          @click="deleteListing('')"
        >
          <span class="align-middle">{{ $t('Eliminar') }}</span>
        </b-button>
      </div>
      <div
        v-if="(optionsListing.delete === '' || ![2].includes(Number(optionsListing.delete))) && optionsListing.userCanExecuteAction === false"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
          :to="{ name: 'listings-view-detail', params: { id: idListing } }"
        >
          <span class="align-middle">{{ $t('Vista completa') }}</span>
        </b-button>
      </div>
      <div
        v-if="optionsListing.location !== ''"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          :href="'https://www.google.pt/maps/place/' + optionsListing.location"
          target="_blank"
          variant="outline-secondary"
          block
        >
          <span class="align-middle">{{ $t('Ver mapa') }}</span>
        </b-button>
      </div>
    </div>

    <div
      v-if="typeof optionsListing !== 'undefined' && optionsListing.actionViewAllRede === '' && optionsListing.nameSectionList !== 'externas' && optionsListing.rascunho === '' && (optionsListing.delete === '' || Number(optionsListing.delete) !== 2)"
      class="form-group-compose text-center compose-btn"
    >
      <div class="d-flex mt-2">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
          :to="{ name: 'listings-view-detail', params: { id: idListing } }"
        >
          <span class="align-middle">{{ $t('Vista completa') }}</span>
        </b-button>
      </div>
      <div
        v-if="optionsListing.nameSectionList === 'externas' && [5, 9, 10, 13].includes(Number(currentUser.id_role))"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
        >
          <span class="align-middle">{{ $t('Iniciar processo de venda') }}</span>
        </b-button>
      </div>
      <div
        v-if="optionsListing.delete === '' && appConfig.bUseEstudoMercado === true && optionsListing.sOptionHaveEM === true && showButtonEM === true"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
        >
          <span class="align-middle">{{ $t('Abrir estudo de mercado') }}</span>
        </b-button>
      </div>
      <div
        v-if="optionsListing.delete === '' && appConfig.bUseEstudoMercado === true && optionsListing.sOptionHaveEM === false && showButtonEM === true"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
        >
          <span class="align-middle">{{ $t('Iniciar estudo de mercado') }}</span>
        </b-button>
      </div>
      <div
        v-if="optionsListing.delete === '' && appConfig.bUseEstudoMercadoAnalise === true"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
          @click.stop.prevent="openViewMarketResearch(idListing)"
        >
          <span class="align-middle">{{ $t('Iniciar estudo de mercado') }}</span>
        </b-button>
      </div>
      <div
        v-if="optionsListing.userCanCreateAction === true && (optionsListing.userHUBIsHM === true || optionsListing.userCanEditAction === true) && ['pendentes', 'myHUB', 'list'].includes(optionsListing.nameSectionList) && ![2 ,3 ,7 ,8].includes(Number(optionsListing.estado))"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
          @click="suspenderListing"
        >
          <span class="align-middle">{{ $t('Suspender') }}</span>
        </b-button>
      </div>
      <div
        v-if="Number(optionsListing.estado) === 9 && optionsListing.userCanCreateAction === true && optionsListing.userCanEditAction === true"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
          :to="{ name: 'listings-edit', params: { id: idListing } }"
        >
          <span class="align-middle">{{ $t('Editar') }}</span>
        </b-button>
      </div>
      <div
        v-if="Number(optionsListing.estado) === 9 && optionsListing.userCanCreateAction === true && optionsListing.userCanDuplAction === true"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
          @click="duplicateListing"
        >
          <span class="align-middle">{{ $t('Duplicar') }}</span>
        </b-button>
      </div>
      <div
        v-if="optionsListing.userCanCreateAction === true && optionsListing.userHUBIsHM === true"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
          @click="deleteListing('')"
        >
          <span class="align-middle">{{ $t('Eliminar') }}</span>
        </b-button>
      </div>
      <div
        v-if="optionsListing.showVersionPending === '' && optionsListing.iFavorite === '' && optionsListing.delete === ''"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
          @click="addFavoritos"
        >
          <span class="align-middle">{{ $t('Adicionar aos favoritos') }}</span>
        </b-button>
      </div>
      <div
        v-if="optionsListing.showVersionPending === '' && optionsListing.iFavorite !== '' && optionsListing.delete === ''"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
          @click="removeFavoritos"
        >
          <span class="align-middle">{{ $t('Remover dos favoritos') }}</span>
        </b-button>
      </div>

      <div
        v-if="(Number(optionsListing.idAng) != Number(decode(currentUser.id))) && (appConfig.bUsePartilhas === true && Number(optionsListing.estado) === 1 && ((optionsListing.iPartilha === '') || ([7].includes(Number(currentUser.id_role)) && Number(optionsListing.totalShares) < 2)
          || ([4].includes(Number(currentUser.id_role)) && currentUser.extras.basic === 1 && Number(optionsListing.totalShares) < 2) || ([4].includes(Number(currentUser.id_role)) && typeof currentUser.extras.idLeader !== 'undefined' && Number(optionsListing.totalShares) < 4)))"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
          @click="gerarPartilha"
        >
          <span class="align-middle">{{ $t('Partilhar') }}</span>
        </b-button>
      </div>

      <div
        v-if="appConfig.bUsePartilhas === true && Number(optionsListing.estado) === 1 && ((optionsListing.iPartilha !== '') || (Number(optionsListing.idAng) === Number(decode(currentUser.id))))"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
          @click="setShowUserShareDetails([true, idListing, pid, (Number(optionsListing.idAng) === Number(decode(currentUser.id)))])"
        >
          <span class="align-middle">{{ $t('Ver partilha') }}</span>
        </b-button>
      </div>
      <div
        v-if="optionsListing.location !== ''"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          :href="'https://www.google.pt/maps/place/' + optionsListing.location"
          target="_blank"
          variant="outline-secondary"
          block
        >
          <span class="align-middle">{{ $t('Ver mapa') }}</span>
        </b-button>
      </div>
    </div>

    <div
      v-if="typeof optionsListing !== 'undefined' && optionsListing.actionViewAllRede !== '' && optionsListing.nameSectionList === 'pendentes'"
      class="form-group-compose text-center compose-btn"
    >
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-secondary"
        block
        :to="optionsListing.showVersionPending !== '' ? { name: 'listings-view-detail-pending', params: { id: idListing, status: 'pending' } } : { name: 'listings-view-detail', params: { id: idListing } }"
      >
        <span class="align-middle">{{ $t('Vista completa') }}</span>
      </b-button>
      <div
        v-if="Number(optionsListing.estado) === 9 && optionsListing.userCanCreateAction === true && optionsListing.userCanExecuteAction === true && optionsListing.userCanEditAction === true"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
          :to="{ name: 'listings-edit', params: { id: idListing } }"
        >
          <span class="align-middle">{{ $t('Editar') }}</span>
        </b-button>
      </div>
      <div
        v-if="Number(optionsListing.estado) === 9 && optionsListing.userCanCreateAction === true && optionsListing.userCanExecuteAction === true && optionsListing.userCanDuplAction === true"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
          @click="duplicateListing"
        >
          <span class="align-middle">{{ $t('Duplicar') }}</span>
        </b-button>
      </div>
      <div
        v-if="optionsListing.userCanCreateAction === true && optionsListing.userCanExecuteAction === true && optionsListing.userHUBIsHM === true && ['pendentes', 'myHUB'].includes(optionsListing.nameSectionList)"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
          @click="suspenderListing"
        >
          <span class="align-middle">{{ $t('Suspender') }}</span>
        </b-button>
      </div>
      <div
        v-if="optionsListing.userCanCreateAction === true && optionsListing.userCanExecuteAction === true && optionsListing.userHUBIsHM === true"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
          @click="deleteListing('')"
        >
          <span class="align-middle">{{ $t('Eliminar') }}</span>
        </b-button>
      </div>
      <div
        v-if="optionsListing.location !== ''"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          :href="'https://www.google.pt/maps/place/' + optionsListing.location"
          target="_blank"
          variant="outline-secondary"
          block
        >
          <span class="align-middle">{{ $t('Ver mapa') }}</span>
        </b-button>
      </div>
    </div>

    <div
      v-if="typeof optionsListing !== 'undefined' && optionsListing.actionViewAllRede !== '' && optionsListing.nameSectionList !== 'pendentes'"
      class="form-group-compose text-center compose-btn"
    >
      <div
        v-if="isPageListviewShare===true"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block

          @click="setShowUserShareDetails([true, idListing, pid, (Number(optionsListing.idAng) === Number(decode(currentUser.id)))])"
        >
          <span
            v-if="['sharedByConsultant', 'sharedByConsultantHUB', 'sharedListings', 'sharedListingsHUB', 'shared'].includes(optionsListing.nameSectionList)"
            class="align-middle"
          >
            {{ $t('Ver partilhas') }}
          </span>
          <span
            v-else
            class="align-middle"
          >
            {{ $t('Ver partilha') }}
          </span>
        </b-button>
      </div>
      <div class="d-flex mt-2">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
          :to="{ name: 'listings-view-detail', params: { id: idListing } }"
        >
          <span class="align-middle">{{ $t('Vista completa') }}</span>
        </b-button>
      </div>

      <div
        v-if="showButtonReserva === true && appConfig.bUsePropostas === true && (optionsListing.userHUBIsHM === true || optionsListing.userCanEditAction === true) && ['myHUB'].includes(optionsListing.nameSectionList)
          && (Number(optionsListing.estado) === 2 || Number(optionsListing.estado_atual) === 2) && Number(optionsListing.locationHUBByUser) === 1 && appConfig.bUseProcessosVenda === true && optionsListing.idProcess !== ''"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
        >
          <span class="align-middle">{{ $t('Abrir processo de venda') }}</span>
        </b-button>
      </div>
      <div
        v-if="showButtonReserva === true && appConfig.bUsePropostas === true && (optionsListing.userHUBIsHM === true || optionsListing.userCanEditAction === true) && ['myHUB'].includes(optionsListing.nameSectionList)
          && (Number(optionsListing.estado) === 2 || Number(optionsListing.estado_atual) === 2)&& Number(optionsListing.locationHUBByUser) === 1 && (appConfig.bUseProcessosVenda === false || optionsListing.idProcess === '')"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
        >
          <span class="align-middle">{{ $t('Passar para "arrendado"') }}</span>
        </b-button>
      </div>
      <div
        v-if="showButtonReserva === true && appConfig.bUsePropostas === true && (optionsListing.userHUBIsHM === true || optionsListing.userCanEditAction === true) && ['myHUB'].includes(optionsListing.nameSectionList)
          && (Number(optionsListing.estado) === 2 || Number(optionsListing.estado_atual) === 2) && Number(optionsListing.locationHUBByUser) === 1 && (appConfig.bUseProcessosVenda === false || optionsListing.idProcess === '')"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
        >
          <span class="align-middle">{{ $t('Passar para "vendido"') }}</span>
        </b-button>
      </div>
      <div
        v-if="showButtonReserva === true && appConfig.bUsePropostas === true && (optionsListing.userHUBIsHM === true || optionsListing.userCanEditAction === true) && ['myHUB'].includes(optionsListing.nameSectionList)
          && (Number(optionsListing.estado) === 2 || Number(optionsListing.estado_atual) === 2) && Number(optionsListing.locationHUBByUser) === 1 && (appConfig.bUseProcessosVenda === false || optionsListing.idProcess === '')"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
        >
          <span class="align-middle">{{ $t('Ação sobre a reserva') }}</span>
        </b-button>
      </div>
      <div
        v-if="appConfig.bUsePropostas === true && (optionsListing.userHUBIsHM === true || optionsListing.userCanEditAction === true) && ['myHUB'].includes(optionsListing.nameSectionList) && Number(optionsListing.locationHUBByUser) === 1
          && (Number(optionsListing.estado) === 2 || Number(optionsListing.estado_atual) === 2)"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
        >
          <span class="align-middle">{{ $t('Ver proposta') }}</span>
        </b-button>
      </div>
      <div
        v-if="showButtonReserva === true && appConfig.bUsePropostas === true && (optionsListing.userHUBIsHM === true || optionsListing.userCanEditAction === true) && ['myHUB'].includes(optionsListing.nameSectionList)
          && Number(optionsListing.locationHUBByUser) === 1 && (Number(optionsListing.estado) === 1 || Number(optionsListing.estado_atual) === 1) && optionsListing.userHUBIsHM === true && optionsListing.delete === ''"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
        >
          <span class="align-middle">{{ $t('Reservar') }}</span>
        </b-button>
      </div>
      <div
        v-if="showButtonReserva === true && appConfig.bUsePropostas === true && (optionsListing.userHUBIsHM === true || optionsListing.userCanEditAction === true) && ['myHUB'].includes(optionsListing.nameSectionList)
          && Number(optionsListing.locationHUBByUser) === 1 && (Number(optionsListing.estado) === 1 || Number(optionsListing.estado_atual) === 1) && optionsListing.userHUBIsHM === true && optionsListing.lastEntry !== '' && optionsListing.delete === ''"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
        >
          <span class="align-middle">{{ $t('Reservar') }}</span>
        </b-button>
      </div>

      <div
        v-if="appConfig.bUseEstudoMercado === true && optionsListing.delete === '' && optionsListing.sOptionHaveEM === true && showButtonEM === true"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
        >
          <span class="align-middle">{{ $t('Abrir estudo de mercado') }}</span>
        </b-button>
      </div>
      <div
        v-if="appConfig.bUseEstudoMercado === true && optionsListing.delete === '' && optionsListing.sOptionHaveEM === false && showButtonEM === true"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
        >
          <span class="align-middle">{{ $t('Iniciar estudo de mercado') }}</span>
        </b-button>
      </div>
      <div
        v-if="appConfig.bUseEstudoMercadoAnalise === true"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
          @click.stop.prevent="openViewMarketResearch(idListing)"
        >
          <span class="align-middle">{{ $t('Iniciar estudo de mercado') }}</span>
        </b-button>
      </div>
      <div
        v-if="Number(optionsListing.estado) === 9 && optionsListing.showButtonsActions === true && optionsListing.showShares === false && (!selectedDataFiltersListview.estado.includes('lixo') || optionsListing.delete === '') && optionsListing.userCanEditAction === true"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
          :to="{ name: 'listings-edit', params: { id: idListing } }"
        >
          <span class="align-middle">{{ $t('Editar') }}</span>
        </b-button>
      </div>
      <div
        v-if="Number(optionsListing.estado) === 9 && optionsListing.showButtonsActions === true && optionsListing.showShares === false && (!selectedDataFiltersListview.estado.includes('lixo') || optionsListing.delete === '') && optionsListing.userCanDuplAction === true"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
          @click="duplicateListing"
        >
          <span class="align-middle">{{ $t('Duplicar') }}</span>
        </b-button>
      </div>
      <div
        v-if="optionsListing.showButtonsActions === true && optionsListing.showShares === false && (!selectedDataFiltersListview.estado.includes('lixo') || optionsListing.delete === '') && optionsListing.userHUBIsHM === true
          && ['pendentes', 'myHUB'].includes(optionsListing.nameSectionList) && [2 ,3 ,7 ,8].includes(Number(optionsListing.estado))"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
          @click="suspenderListing"
        >
          <span class="align-middle">{{ $t('Suspender') }}</span>
        </b-button>
      </div>
      <div
        v-if="optionsListing.showButtonsActions === true && optionsListing.showShares === false && (!selectedDataFiltersListview.estado.includes('lixo') || optionsListing.delete === '') && optionsListing.userHUBIsHM === true"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
          @click="deleteListing('')"
        >
          <span class="align-middle">{{ $t('Eliminar') }}</span>
        </b-button>
      </div>
      <div
        v-if="optionsListing.showButtonsActions === true && optionsListing.showShares === false && (selectedDataFiltersListview.estado.includes('lixo') && optionsListing.delete !== '') && [5, 9, 10, 13].includes(Number(currentUser.id_role))"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
          @click="restaurarListing"
        >
          <span class="align-middle">{{ $t('Restaurar') }}</span>
        </b-button>
      </div>
      <div
        v-if="optionsListing.showVersionPending === '' && appConfig.bUseFavorites === true && optionsListing.delete === '' && optionsListing.iFavorite === ''"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
          @click="addFavoritos"
        >
          <span class="align-middle">{{ $t('Adicionar aos favoritos') }}</span>
        </b-button>
      </div>
      <div
        v-if="optionsListing.showVersionPending === '' && appConfig.bUseFavorites === true && optionsListing.delete === '' && optionsListing.iFavorite !== ''"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
          @click="removeFavoritos"
        >
          <span class="align-middle">{{ $t('Remover dos favoritos') }}</span>
        </b-button>
      </div>

      <div
        v-if="(Number(optionsListing.idAng) != Number(decode(currentUser.id))) && (appConfig.bUsePartilhas === true && Number(optionsListing.estado) === 1 && ((optionsListing.iPartilha === '') || ([7].includes(Number(currentUser.id_role)) && Number(optionsListing.totalShares) < 2)
          || ([4].includes(Number(currentUser.id_role)) && currentUser.extras.basic === 1 && Number(optionsListing.totalShares) < 2) || ([4].includes(Number(currentUser.id_role)) && typeof currentUser.extras.idLeader !== 'undefined' && Number(optionsListing.totalShares) < 4)))"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
          @click="gerarPartilha"
        >
          <span class="align-middle">{{ $t('Partilhar') }}</span>
        </b-button>
      </div>

      <div
        v-if="(appConfig.bUsePartilhas === true) && (Number(optionsListing.estado) === 1) && ((optionsListing.iPartilha !== '') || (Number(optionsListing.idAng) == Number(decode(currentUser.id)))) && (isPageListviewShare === false)"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          block
          @click="setShowUserShareDetails([true, idListing, pid, (Number(optionsListing.idAng) === Number(decode(currentUser.id)))])"
        >
          <span class="align-middle">{{ $t('Ver partilha') }}</span>
        </b-button>
      </div>
      <div
        v-if="optionsListing.location !== ''"
        class="d-flex mt-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          :href="'https://www.google.pt/maps/place/' + optionsListing.location"
          target="_blank"
          variant="outline-secondary"
          block
        >
          <span class="align-middle">{{ $t('Ver mapa') }}</span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import useCapacitor from '@core-custom/utils/capacitor'
import { showMsgRequest } from '@core-custom/mixins/geral'

export default {
  components: {
    BButton,
  },
  directives: {
    Ripple,
  },
  mixins: [showMsgRequest],
  props: {
    setShowUserShareDetails: {
      type: Function,
      required: false,
      default: null,
    },
    showQuickView: {
      type: Boolean,
      required: false,
      default: false,
    },
    showQuickViewGeneral: {
      type: Boolean,
      required: false,
      default: false,
    },
    openLeftSidebar: {
      type: Function,
      required: false,
      default: null,
    },
    reloadQuickView: {
      type: Function,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      showButtonEM: false,
      showButtonVer: false,
      showButtonSubmit: false,
      showButtonReserva: false,
    }
  },
  computed: {
    ...mapGetters('listings', ['idListing', 'pid', 'optionsListing', 'appConfig', 'selectedDataFiltersListview']),
    ...mapGetters('auth', ['currentUser']),
    isPageListviewShare() {
      if (['myShares', 'shared', 'sharedByConsultant', 'sharedByConsultantHUB', 'sharedListings', 'sharedListingsHUB'].includes(this.optionsListing.nameSectionList)) {
        return true
      }

      return false
    },
  },
  methods: {
    closeModalOptions(val) {
      this.$store.dispatch('listings/setShowOptions', val).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    closeQuickView(val) {
      this.$store.dispatch('listings/setShowListingQuickView', val).catch(error => {
        this.showMsgErrorRequest(error)
      })
      this.openLeftSidebar(false)
    },
    decode(val) {
      return atob(val)
    },
    async duplicateListing() {
      this.$swal({
        title: '',
        html: `${this.$t('Pretende duplicar a angariação?')} <br><small>${this.$t('Duplica toda a informação excepto as fotografias privadas e as imagens principais')}</small>`,
        icon: 'question',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.$t('Sim'),
        cancelButtonText: this.$t('Não'),
        customClass: {
          confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
          cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
        },
        buttonsStyling: false,
      }).then(async resp => {
        if (resp.value) {
          this.$swal({
            title: '',
            html: `${this.$t('Pretende duplicar as fotografias da angariação?')}`,
            icon: 'question',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: this.$t('Sim'),
            cancelButtonText: this.$t('Não'),
            customClass: {
              confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
              cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
            },
            buttonsStyling: false,
          }).then(async response => {
            this.$root.$emit('app::loading', true)
            if (response.value) {
              await this.$store.dispatch('listings/duplicateListing', { photos: 1 }).then(async resDuplicate => {
                if (Number(resDuplicate.error) !== 1) {
                  await this.$router.push({ path: `/listings/edit/${resDuplicate.newId}` }).then(() => {
                    this.$root.$emit('app::loading', false)
                  })
                } else {
                  this.$root.$emit('app::loading', false)
                  this.showMsgErrorRequest({ message: resDuplicate.msg })
                }
              }).catch(error => {
                this.$root.$emit('app::loading', false)
                this.showMsgErrorRequest(error)
              })
            } else {
              await this.$store.dispatch('listings/duplicateListing', { photos: 0 }).then(async resDuplicate => {
                if (Number(resDuplicate.error) !== 1) {
                  await this.$router.push({ path: `/listings/edit/${resDuplicate.newId}` }).then(() => {
                    this.$root.$emit('app::loading', false)
                  })
                } else {
                  this.$root.$emit('app::loading', false)
                  this.showMsgErrorRequest({ message: resDuplicate.msg })
                }
              }).catch(error => {
                this.$root.$emit('app::loading', false)
                this.showMsgErrorRequest(error)
              })
            }
          })
        }
      })
    },
    async addFavoritos() {
      this.$swal({
        title: '',
        html: `${this.$t('Pretende adicionar a angariação aos favoritos?')}`,
        icon: 'question',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.$t('Sim'),
        cancelButtonText: this.$t('Não'),
        customClass: {
          confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
          cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
        },
        buttonsStyling: false,
      }).then(async resp => {
        if (resp.value) {
          await this.$store.dispatch('listings/addFavorite').then(async res => {
            this.$root.$emit('app::loading', false)
            if (Number(res.error) !== 1) {
              this.showMsgSuccessRequest({ message: res.msg })

              if (this.showQuickViewGeneral === true) {
                await this.reloadQuickView()
              } else {
                if (this.showQuickView === false) {
                  await this.closeModalOptions(true)
                } else {
                  await this.closeQuickView([false, null])
                }

                await this.$store.dispatch('listings/fetchListView').catch(error => {
                  this.showMsgErrorRequest(error)
                })
              }
            } else {
              this.showMsgErrorRequest({ message: res.msg })
            }
          }).catch(error => {
            this.showMsgErrorRequest(error)
          })
        }
      })
    },
    async removeFavoritos() {
      this.$swal({
        title: '',
        html: `${this.$t('Pretende remover a angariação dos favoritos?')}`,
        icon: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.$t('Sim'),
        cancelButtonText: this.$t('Não'),
        customClass: {
          confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
          cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
        },
        buttonsStyling: false,
      }).then(async resp => {
        if (resp.value) {
          await this.$store.dispatch('listings/removeFavorite').then(async res => {
            this.$root.$emit('app::loading', false)
            if (Number(res.error) !== 1) {
              this.showMsgSuccessRequest({ message: res.msg })

              if (this.showQuickViewGeneral === true) {
                await this.reloadQuickView()
              } else {
                if (this.showQuickView === false) {
                  await this.closeModalOptions(true)
                } else {
                  await this.closeQuickView([false, null])
                }

                await this.$store.dispatch('listings/fetchListView').catch(error => {
                  this.showMsgErrorRequest(error)
                })
              }
            } else {
              this.showMsgErrorRequest({ message: res.msg })
            }
          }).catch(error => {
            this.showMsgErrorRequest(error)
          })
        }
      })
    },
    async suspenderListing() {
      this.$swal({
        title: '',
        html: `${this.$t('Esta ação vai ter efeito imediato e a angariação deixará de ficar disponível no {nome} e no website.', { nome: process.env.VUE_APP_TITLE })}<br>${this.$t('Deseja continuar?')}`,
        icon: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.$t('Sim'),
        cancelButtonText: this.$t('Não'),
        customClass: {
          confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
          cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
        },
        buttonsStyling: false,
      }).then(async resp => {
        if (resp.value) {
          await this.$store.dispatch('listings/suspenderReg').then(async res => {
            this.$root.$emit('app::loading', false)
            if (Number(res.error) !== 1) {
              this.showMsgSuccessRequest({ message: res.msg })

              if (this.showQuickViewGeneral === true) {
                await this.reloadQuickView()
              } else {
                if (this.showQuickView === false) {
                  await this.closeModalOptions(true)
                } else {
                  await this.closeQuickView([false, null])
                }

                await this.$store.dispatch('listings/fetchListView').catch(error => {
                  this.showMsgErrorRequest(error)
                })
              }
            } else {
              this.showMsgErrorRequest({ message: res.msg })
            }
          }).catch(error => {
            this.showMsgErrorRequest(error)
          })
        }
      })
    },
    async deleteListing(confirmations = {}) {
      this.$root.$emit('app::loading', true)
      await this.$store.dispatch('listings/deleteListing', { config: confirmations }).then(async res => {
        this.$root.$emit('app::loading', false)
        if (Number(res.error) === 1) {
          this.showMsgErrorRequest({ message: res.msg })
        } else if (Object.keys(res.required_confirmations).length > 0) {
          this.showNextConfirmation(res.required_confirmations, {})
        } else {
          this.showMsgSuccessRequest({ message: res.msg })

          if (this.showQuickViewGeneral === true) {
            await this.reloadQuickView()
          } else {
            if (this.showQuickView === false) {
              await this.closeModalOptions(true)
            } else {
              await this.closeQuickView([false, null])
            }

            await this.$store.dispatch('listings/fetchListView').catch(error => {
              this.showMsgErrorRequest(error)
            })
          }
        }
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    async showNextConfirmation(requiredConfirmations, confirmations = {}) {
      const requiredConfirm = requiredConfirmations
      const confirmKey = Object.keys(requiredConfirm)[0]
      const confirmMessage = requiredConfirm[confirmKey]
      const confirm = confirmations

      this.$swal({
        title: '',
        html: confirmMessage,
        icon: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.$t('Eliminar'),
        cancelButtonText: this.$t('Cancelar'),
        customClass: {
          confirmButton: 'btn btn-danger ml-sm-1 btn-xs-block',
          cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
        },
        buttonsStyling: false,
      }).then(async resp => {
        confirm[confirmKey] = resp.value
        delete requiredConfirm[confirmKey]
        if (resp.value) {
          if (Object.keys(requiredConfirm).length > 0) {
            this.showNextConfirmation(requiredConfirm, confirm)
          } else {
            this.deleteListing(confirm)
          }
        }
      })
    },
    async restaurarListing() {
      this.$swal({
        title: '',
        html: this.$t('Pretende restaurar a angariação?'),
        icon: 'question',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.$t('Sim'),
        cancelButtonText: this.$t('Não'),
        customClass: {
          confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
          cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
        },
        buttonsStyling: false,
      }).then(async resp => {
        if (resp.value) {
          await this.$store.dispatch('listings/restoreListing').then(async res => {
            this.$root.$emit('app::loading', false)
            if (Number(res.error) !== 1) {
              this.showMsgSuccessRequest({ message: res.msg })

              if (this.showQuickViewGeneral === true) {
                await this.reloadQuickView()
              } else {
                if (this.showQuickView === false) {
                  await this.closeModalOptions(true)
                } else {
                  await this.closeQuickView([false, null])
                }

                await this.$store.dispatch('listings/fetchListView').catch(error => {
                  this.showMsgErrorRequest(error)
                })
              }

              if (res.status === 1) {
                const urlPrevRoute = `/listings/viewDetail/${this.idListing}?portals=true`
                this.$router.push({ path: urlPrevRoute }).then(() => {
                  this.$root.$emit('app::loading', false)
                })
              }
            } else {
              this.showMsgErrorRequest({ message: res.msg })
            }
          }).catch(error => {
            this.showMsgErrorRequest(error)
          })
        }
      })
    },
    async gerarPartilha() {
      if ((this.currentUser.extras.basic === 1) || (Number(atob(this.currentUser.id)) === Number(this.currentUser.extras.idLeader))) {
        await this.gerarPartilhaUrlModalVideo(null)
      } else {
        this.$swal({
          title: '',
          html: this.$t('Pretendo um URL em:'),
          showCancelButton: true,
          showDenyButton: true,
          reverseButtons: true,
          cancelButtonText: this.$t('Cancelar'),
          denyButtonText: this.$t('Meu nome'),
          confirmButtonText: this.$t('Nome do Agente Líder'),
          customClass: {
            cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
            denyButton: 'btn btn-outline-success ml-sm-1 mb-1 mb-sm-0 btn-xs-block',
            confirmButton: 'btn btn-outline-primary ml-sm-1 btn-xs-block',
          },
          buttonsStyling: false,
          width: '40rem',
        }).then(async result => {
          if (result.isConfirmed === true) {
            await this.gerarPartilhaUrlModalVideo(2)
          } else if (result.isDenied === true) {
            await this.gerarPartilhaUrlModalVideo(1)
          }
        })
      }
    },
    async gerarPartilhaUrlModalVideo(optionTeam) {
      this.$swal({
        title: '',
        html: `${this.$t('Como pretende gerar a partilha para a angariação')} <b>${this.pid}</b> ?`,
        showCancelButton: true,
        showDenyButton: true,
        reverseButtons: true,
        cancelButtonText: this.$t('Cancelar'),
        denyButtonText: this.$t('Ocultar Vídeo / VR'),
        confirmButtonText: this.$t('Incluir Vídeo / VR'),
        customClass: {
          cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
          denyButton: 'btn btn-outline-warning ml-sm-1 mb-1 mb-sm-0 btn-xs-block',
          confirmButton: 'btn btn-outline-primary ml-sm-1 btn-xs-block',
        },
        buttonsStyling: false,
        width: '40rem',
      }).then(async result => {
        if (result.isConfirmed === true) {
          await this.gerarPartilhaConsultor(optionTeam, true)
        } else if (result.isDenied === true) {
          await this.gerarPartilhaConsultor(optionTeam, false)
        }
      })
    },
    async gerarPartilhaConsultor(optionTeam, incluirVR) {
      this.$root.$emit('app::loading', true)
      await this.$store.dispatch('listings/gerarPartilhaConsultor', { equipaOpcao: optionTeam, sw139s11: incluirVR }).then(async res => {
        this.$root.$emit('app::loading', false)
        if (Number(res.error) !== 1) {
          this.showMsgSuccessRequest({ message: res.msg })
          await this.setShowUserShareDetails([true, this.idListing, this.pid]).then(async resp => {
            if (typeof resp === 'undefined' || resp === true) {
              if (this.showQuickViewGeneral === true) {
                await this.reloadQuickView()
              } else {
                await this.$store.dispatch('listings/fetchListView')

                if (this.showQuickView === true) {
                  await this.closeQuickView([false, null])
                }
              }
            }
          }).catch(error => {
            this.showMsgErrorRequest(error)
          })
          await this.closeModalOptions(true)
        } else {
          this.showMsgErrorRequest({ message: res.msg })
        }
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    async openViewMarketResearch(idImovel) {
      this.$root.$emit('app::loading', true)
      await this.$store.dispatch('listings/getMarketStudy', { id: idImovel }).then(async url => {
        this.$root.$emit('app::loading', false)

        if (url !== '') {
          useCapacitor.openUrlNewTab(url)
        }
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
  },
}
</script>
