<template>
  <div class="email-app-details">
    <!-- Share Header -->
    <div class="email-detail-header">
      <!-- Header: Left -->
      <div class="email-header-left d-flex align-items-center">
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            :size="sizeIconsAction"
            class="cursor-pointer"
            @click="openLeftSidebar(true)"
          />
        </div>
        <span class="go-back ml-1 mr-1">
          <feather-icon
            :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
            size="20"
            class="align-bottom"
            @click="$emit('close-quick-view')"
          />
        </span>
        <h4 class="email-subject mb-0">
          <b-row>
            <b-col
              md="12"
            >
              <span v-if="dataQuickView.infoListing.sw012s02 !== ''">{{ dataQuickView.infoListing.sw012s02 }}</span>
              <span v-else>{{ $t('Pré-angariação') }}</span>
            </b-col>
          </b-row>
        </h4>
      </div>
    </div>
    <div class="email-app-list ecommerce-application position-relative space-bottom">

      <vue-perfect-scrollbar
        class="email-user-list scroll-area"
        :settings="configSettingsScroll"
      >

        <b-container
          class="p-2"
          fluid
        >

          <info-Listing
            :get-modal-rating-listing="getModalRatingListing"
          />

        </b-container>

      </vue-perfect-scrollbar>

    </div>

  </div>

</template>

<script>
import {
  VBTooltip, BContainer, BCol, BRow,
} from 'bootstrap-vue'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import Ripple from 'vue-ripple-directive'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { mapGetters } from 'vuex'
import { configSettingsScroll } from '@core-custom/utils/ui'
import InfoListing from '@/views/modules/components/listing/infoQuickView.vue'
import 'swiper/css/swiper.css'

export default {
  components: {
    BContainer,
    BCol,
    BRow,
    VuePerfectScrollbar,
    InfoListing,

  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [sizeIconsAction],
  props: {
    getModalRatingListing: {
      type: Function,
      required: true,
    },
    openLeftSidebar: {
      type: Function,
      required: false,
      default: null,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('listings', ['dataQuickView']),
  },
  setup() {
    return {
      configSettingsScroll,
    }
  },
}
</script>

<style lang="scss" scoped>
.email-application .email-app-details {
  z-index: 3!important;
}

.email-app-list .email-app-details {
  width: 100%!important;
}

.email-app-details .email-user-list {
  height: calc( 100% - calc(5rem) )!important;
}

.capacitor{
  .email-user-list ::v-deep{
    .email-media-list li {
      animation:none!important;
      animation-fill-mode:none!important;
    }
  }
}

</style>
