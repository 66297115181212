import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import checkNested from 'check-nested'
import defaultState from './defaultState'

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    pageSel: state => state.pageSel.value,
    loadingListview: state => state.loadingListview.value,
    listings: state => state.listings.value,
    totalListings: state => state.totalListings.value,
    currentPage: state => state.currentPage.value,
    perPage: state => state.perPage.value,
    searchTxt: state => state.searchTxt.value,
    sortBy: state => state.sortBy.value,
    sortByOptions: state => state.sortByOptions,
    dataFiltersListview: state => ({
      estados: state.filterEstadoAng.value,
      estadosExternas: state.filterEstadosExternas.value,
      tipoImoveis: state.filterTiposImoveis.value,
      tipoNegocios: state.filterTipoNegocios.value,
      tipologias: state.filterTipologias.value,
      condicoes: state.filterCondicoesImovel.value,
      classesTipologias: state.filterClassesTipologias.value,
      permutas: state.filterPermutas.value,
      atributos: state.filterAtributos.value,
      atributosG: state.filterAtributosG.value,
      optionsData: state.filterOptionsData.value,
      datas: state.filterDatas.value,
      loadData: state.loadData.value,
      tiposTerrenos: state.filterTiposTerrenos.value,
      load: state.filterLoad.value,
      array70: state.array70.value,
    }),
    selectedDataFiltersListview: state => ({
      estado: state.estado.value,
      estadoExterna: state.estadoExterna.value,
      tipoNegocio: state.tipoNegocio.value,
      tipoImovel: state.tipoImovel.value,
      tipologia: state.tipologia.value,
      fsw012s08_desde: state.fsw012s08_desde.value,
      fsw012s08_ate: state.fsw012s08_ate.value,
      fsw012s14_desde: state.fsw012s14_desde.value,
      fsw012s14_ate: state.fsw012s14_ate.value,
      fsw012s11: state.fsw012s11.value,
      fsw012s26: state.fsw012s26.value,
      fsw012s28: state.fsw012s28.value,
      fsw012s29: state.fsw012s29.value,
      fsw012s30: state.fsw012s30.value,
      fsw012s298: state.fsw012s298.value,
      fsw012s331: state.fsw012s331.value,
      atributo: state.atributo.value,
      data: state.data.value,
      tipoData: state.tipoData.value,
      intervaloData: state.intervaloData.value,
      intervaloDataEnd: state.intervaloDataEnd.value,
      fsw012s09: state.fsw012s09.value,
    }),
    getFilterEstado: state => state.estado.value,
    listviewOrderBy: state => state.listviewOrderBy.value,
    listview_txt_default: state => ({
      txt: state.listview_txt_default,
      txt_not_find: state.listiview_txt_not_find,
      txt_search: state.listiview_txt_search,
      txt_loading: state.listview_txt_loading,
    }),
    listviewInit: state => state.listviewInit.value,
    totalFields: state => state.filterSelectedTotalFields.value,
    buttonSearch: state => state.buttonSearch.value,
    showOptionsListing: state => state.showOptionsListing.value,
    pid: state => state.pid.value,
    optionsListing: state => state.optionsListing.value,
    idListing: state => state.idListing.value,
    appConfig: state => state.appConfig.value,
    positionListview: state => state.positionListview,
    showFilterTipologia: state => {
      let showcltipo = 0
      let showTipoTerreno = 0
      let showAttrRooms = 0

      if (state.tipologia.value.length > 0) {
        state.tipologia.value.forEach(val => {
          if (val === 0) {
            showcltipo += 1
            showTipoTerreno += 1
            showAttrRooms += 1
          } else {
            const infoImovel = state.filterTipologias.value.find(o => o.id === val)

            if ((infoImovel !== null) && (infoImovel !== undefined) && (infoImovel.id !== '')) {
              if (Number(infoImovel.show_cl_tipologia) === 1) {
                showcltipo += 1
              }
              if (Number(infoImovel.show_tipo_terreno) === 1) {
                showTipoTerreno += 1
              }
              if (Number(infoImovel.show_field_atrib_rooms) === 1) {
                showAttrRooms += 1
              }
            }
          }
        })
      }

      return {
        showcltipo,
        showTipoTerreno,
        showAttrRooms,
      }
    },
    pageLoadSel: state => state.pageLoadSel.value,
    showListingQuickView: state => state.showListingQuickView.value,
    dataQuickView: state => ({
      infoMkt: state.infoMkt.value,
      infoConsultor: state.infoConsultor.value,
      infoDataLead: state.infoDataLead.value,
      infoDataBank: state.infoDataBank.value,
      infoListing: state.infoListing.value,
      listingSel: state.listingSel.value,
      headerListing: state.headerListing.value,
    }),
  },
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState)
    },
    setLoadingListview(state, payload) {
      state.loadingListview.value = payload
    },
    setListviewData(state, payload) {
      state.listings.value = payload
    },
    setTotalListings(state, payload) {
      state.totalListings.value = payload
    },
    setLoadPage(state, payload) {
      state.loadData.value = payload
    },
    setFilterEstadosListings(state, payload) {
      try {
        const aEstados = {}

        if (payload.pageSel === 'listings-listview-rede') {
          aEstados['1'] = i18n.t('Ativo')
          aEstados['2'] = i18n.t('Reservado')
          aEstados['7'] = i18n.t('Arrendado')
          aEstados['3'] = i18n.t('Vendido')
          aEstados['4'] = i18n.t('Cancelado')

          if ([1, 8].includes(Number(payload.currentUser.id_role))) {
            aEstados['5'] = i18n.t('Pendente')
            aEstados['6'] = i18n.t('Recusado')
            aEstados['8'] = i18n.t('Suspenso')
            aEstados['9'] = i18n.t('Pré-angariações')
            aEstados.lixo = i18n.t('Lixo')
          }

          if ([1].includes(Number(payload.currentUser.id_role))) {
            aEstados['5#1'] = i18n.t('Rascunho')
          }
        } else if (payload.pageSel === 'listings-favorites') {
          aEstados['1'] = i18n.t('Ativo')
          aEstados['2'] = i18n.t('Reservado')
          aEstados['7'] = i18n.t('Arrendado')
          aEstados['3'] = i18n.t('Vendido')
        } else if (['listings-myShares', 'listings-shared', 'listings-shares', 'listings-shared-HUB'].includes(payload.pageSel)) {
          aEstados['1'] = i18n.t('Ativo')
          aEstados['2'] = i18n.t('Reservado')
          aEstados['7'] = i18n.t('Arrendado')
          aEstados['3'] = i18n.t('Vendido')
          aEstados['4'] = i18n.t('Cancelado')
          aEstados['8'] = i18n.t('Suspenso')
        } else if (payload.pageSel === 'listings-listview-pendentes') {
          aEstados['5'] = i18n.t('Pendente')
          aEstados['6'] = i18n.t('Recusado')
          aEstados['8'] = i18n.t('Suspenso')

          if ([5, 9, 10, 13].includes(Number(payload.currentUser.id_role))) {
            aEstados['5#1'] = i18n.t('Rascunho')
          }
        } else if (['listings-listview-myHUBLocation', 'listings-listview-myHUB'].includes(payload.pageSel)) {
          aEstados['1'] = i18n.t('Ativo')

          if (![4, 7].includes(Number(payload.currentUser.id_role))) {
            aEstados['8'] = i18n.t('Para aprovação')
          }

          aEstados['2'] = i18n.t('Reservado')
          aEstados['7'] = i18n.t('Arrendado')
          aEstados['3'] = i18n.t('Vendido')
          aEstados['4'] = i18n.t('Cancelado')
          aEstados.lixo = i18n.t('Lixo')
        } else if (payload.pageSel === 'listings-listview-externas') {
          aEstados['5#1'] = i18n.t('Externas')
          aEstados['2#3#7'] = i18n.t('Externas em PV')
          aEstados.lixo = i18n.t('Lixo')
        } else if (['listings-shared-byConsultant', 'listings-shared-byConsultantHUB'].includes(payload.pageSel)) {
          // Não adiciona nenhum estado
        } else {
          aEstados['1'] = i18n.t('Ativo')
          aEstados['2'] = i18n.t('Reservado')
          aEstados['7'] = i18n.t('Arrendado')
          aEstados['3'] = i18n.t('Vendido')
          aEstados['4'] = i18n.t('Cancelado')
          aEstados['5'] = i18n.t('Pendente')
          aEstados['6'] = i18n.t('Recusado')
          aEstados['8'] = i18n.t('Suspenso')

          if ((payload.currentUser.extras.basic === 1) || (Object.values(payload.currentUser.extras.perm).includes('add_ang_C'))) {
            aEstados['5#1'] = i18n.t('Rascunho')
          }

          aEstados['9#1'] = i18n.t('Pré-angariações')

          if ([4, 7].includes(Number(payload.currentUser.id_role))) {
            aEstados.lixo = i18n.t('Lixo')
          }
        }

        state.filterEstadoAng.value = aEstados
      } catch (err) {
        //
      }
    },
    setFilterTiposImoveis(state, payload) {
      state.filterTiposImoveis.value = payload
    },
    setFilterTipoNegocios(state, payload) {
      state.filterTipoNegocios.value = payload
    },
    setFilterTipologias(state, payload) {
      state.filterTipologias.value = payload
    },
    setFilterTipologiasInit(state, payload) {
      state.filterTipologiasIni = payload
    },
    setFilterTipologiasH(state, payload) {
      state.filterTipologiasH = payload
    },
    setFilterTipologiasC(state, payload) {
      state.filterTipologiasC = payload
    },
    setFilterTipologiasM(state, payload) {
      state.filterTipologiasM = payload
    },
    setFilterCondicoesImovel(state, payload) {
      state.filterCondicoesImovel.value = payload
    },
    setFilterClassesTipologias(state, payload) {
      state.filterClassesTipologias.value = payload
    },
    setFilterEstado(state, payload) {
      const indexItem = state.estado.value.indexOf(payload)

      if (payload === 0 && indexItem === -1) {
        state.estado.value = []
        state.estado.value.push(0)
      } else {
        const indexAll = state.estado.value.indexOf(0)

        if (indexItem === -1) {
          state.estado.value.push(payload)
        } else {
          state.estado.value.splice(indexItem, 1)
        }

        if (state.estado.value.length === 0) {
          state.estado.value.push(0)
        } else if (indexAll !== -1) {
          state.estado.value.splice(indexAll, 1)
        }
      }
    },
    setFilterEstadoExterna(state, payload) {
      state.estadoExterna.value = []
      state.estadoExterna.value.push(payload)
    },
    setFilterTipoImovel(state, payload) {
      state.tipoImovel.value = payload

      if (payload === '1' || payload === '4') {
        state.filterTipologias.value = state.filterTipologiasH
      } else if (payload === '2' || payload === '5') {
        state.filterTipologias.value = state.filterTipologiasC
      } else if (payload === '3' || payload === '6') {
        state.filterTipologias.value = state.filterTipologiasM
      } else {
        state.filterTipologias.value = state.filterTipologiasIni
      }
    },
    setFilterTipoNegocio(state, payload) {
      const indexItem = state.tipoNegocio.value.indexOf(payload)

      if (payload === 0 && indexItem === -1) {
        state.tipoNegocio.value = []
        state.tipoNegocio.value.push(0)
      } else {
        const indexAll = state.tipoNegocio.value.indexOf(0)

        if (indexItem === -1) {
          state.tipoNegocio.value.push(payload)
        } else {
          state.tipoNegocio.value.splice(indexItem, 1)
        }

        if (state.tipoNegocio.value.length === 0) {
          state.tipoNegocio.value.push(0)
        } else if (indexAll !== -1) {
          state.tipoNegocio.value.splice(indexAll, 1)
        }
      }
    },
    setFilterTipologia(state, payload) {
      const indexItem = state.tipologia.value.indexOf(payload)

      if (payload === 0 && indexItem === -1) {
        state.tipologia.value = []
        state.tipologia.value.push(0)
      } else {
        const indexAll = state.tipologia.value.indexOf(0)

        if (indexItem === -1) {
          state.tipologia.value.push(payload)
        } else {
          state.tipologia.value.splice(indexItem, 1)
        }

        if (state.tipologia.value.length === 0) {
          state.tipologia.value.push(0)
        } else if (indexAll !== -1) {
          state.tipologia.value.splice(indexAll, 1)
        }
      }
    },
    setFilterTipologiaClearFields(state, payload) {
      if (payload.showTipoTerreno === 0) {
        state.fsw012s09.value = ''
      }

      if (payload.showcltipo === 0) {
        state.fsw012s08_ate.value = ''
        state.fsw012s08_desde.value = ''
      }
    },
    setAtributo(state, payload) {
      const indexItem = state.atributo.value.indexOf(payload)

      if (payload === 0 && indexItem === -1) {
        state.atributo.value = []
      } else {
        const indexAll = state.atributo.value.indexOf(0)

        if (indexItem === -1) {
          state.atributo.value.push(payload)
        } else {
          state.atributo.value.splice(indexItem, 1)
        }

        if (state.atributo.value.length === 0) {
          state.atributo.value = []
        } else if (indexAll !== -1) {
          state.atributo.value.splice(indexAll, 1)
        }
      }
    },
    setData(state, payload) {
      if (payload === null) {
        state.data.value = ''
      } else {
        state.data.value = payload
      }
    },
    setTipoData(state, payload) {
      state.tipoData.value = payload
    },
    setIntervaloData(state, payload) {
      state.intervaloData.value = payload
    },
    setIntervaloDataEnd(state, payload) {
      state.intervaloDataEnd.value = payload
    },
    setSearchTxt(state, payload) {
      state.searchTxt.value = payload
    },
    setCurrentPage(state, payload) {
      state.currentPage.value = payload
    },
    setListviewOrderBy(state, payload) {
      state.listviewOrderBy.value = {
        sort: payload.sort,
        column: payload.column,
      }
    },
    addFilterSelectedTotalFields(state) {
      state.filterSelectedTotalFields.value += 1
    },
    resetFilterSelectedTotalFields(state) {
      state.filterSelectedTotalFields.value = 0
    },
    resetAllFilterSelected(state) {
      state.estado.value = 0
      state.tipoNegocio.value = 0
      state.tipoImovel.value = 0
      state.tipologia.value = 0
      state.filterSelectedTotalFields.value = 0
      state.searchTxt.value = ''
    },
    setListviewInit(state, payload) {
      state.listviewInit.value = payload
    },
    setPageSel(state, payload) {
      state.pageSel.value = payload
    },
    setButtonSearch(state, payload) {
      state.buttonSearch.value = payload
    },
    setFsw012s08_desde(state, payload) {
      state.fsw012s08_desde.value = payload
    },
    setFsw012s08_ate(state, payload) {
      state.fsw012s08_ate.value = payload
    },
    setFsw012s14_desde(state, payload) {
      state.fsw012s14_desde.value = payload
    },
    setFsw012s14_ate(state, payload) {
      state.fsw012s14_ate.value = payload
    },
    setFsw012s11(state, payload) {
      state.fsw012s11.value = payload
    },
    setFsw012s26(state, payload) {
      state.fsw012s26.value = payload
    },
    setDistritos(state, payload) {
      state.fsw012s28.value = payload || []
    },
    setConcelhos(state, payload) {
      state.fsw012s29.value = payload || []
    },
    setFreguesias(state, payload) {
      state.fsw012s30.value = payload || []
    },
    setComunidades(state, payload) {
      state.fsw012s28.value = payload || []
    },
    setProvincias(state, payload) {
      state.fsw012s29.value = payload || []
    },
    setComarcas(state, payload) {
      state.fsw012s30.value = payload || []
    },
    setMunicipios(state, payload) {
      state.fsw012s331.value = payload || []
    },
    setZonas(state, payload) {
      state.fsw012s298.value = payload || []
    },
    setSortBy(state, payload) {
      state.sortBy.value = payload
    },
    setAppConfig(state, payload) {
      state.appConfig.value = payload
    },
    resetAllFilterSearchAdvance(state) {
      state.fsw012s08_desde.value = ''
      state.fsw012s08_ate.value = ''
      state.fsw012s14_desde.value = 0
      state.fsw012s14_ate.value = 0
      state.fsw012s11.value = ''
      state.fsw012s26.value = ''
      state.fsw012s28.value = []
      state.fsw012s29.value = []
      state.fsw012s30.value = []
      state.fsw012s298.value = []
      state.fsw012s331.value = []
      state.atributo.value = []
      state.data.value = ''
      state.tipoData.value = ''
      state.intervaloData.value = ''
      state.intervaloDataEnd.value = ''
      state.fsw012s09.value = ''
    },
    setShowNewListing(state, payload) {
      state.showNewListing.value = payload
    },
    setShowOptions(state, payload) {
      state.showOptionsListing.value = payload
    },
    setPid(state, payload) {
      state.pid.value = payload
    },
    setOptionsListing(state, payload) {
      state.optionsListing.value = payload
    },
    setIdListing(state, payload) {
      state.idListing.value = payload
    },
    savePositionListview(state, payload) {
      state.positionListview = payload || {}
    },
    setHistoryKeyStorage(state, payload) {
      state.historyKeyStorage = payload || ''
    },
    saveStates(state) {
      if (state.historyKeyStorage !== '') {
        const oStatesHistory = []

        state.statesToLocalStorage.forEach(elm => {
          if (state[elm] !== undefined) {
            oStatesHistory.push({
              key: elm,
              value: state[elm],
            })
          }
        })

        localStorage.setItem(state.historyKeyStorage, JSON.stringify(oStatesHistory))
      }
    },
    initStates(state) {
      if (state.historyKeyStorage !== '') {
        if ((localStorage.getItem(state.historyKeyStorage) !== null) && (localStorage.getItem(state.historyKeyStorage) !== '')) {
          try {
            const stateCustom = JSON.parse(localStorage.getItem(state.historyKeyStorage))
            if (stateCustom.length > 0) {
              stateCustom.forEach(field => {
                if (('key' in field) && ('value' in field)) {
                  if (state.statesToLocalStorage.includes(field.key)) {
                    Object.assign(state[field.key], field.value)
                  }
                }
              })
            }
          } catch (err) {
            localStorage.removeItem(state.historyKeyStorage)
            //
          }
        }
      }
    },
    setFilterTiposTerrenos(state, payload) {
      state.filterTiposTerrenos.value = payload
    },
    setFsw012s09(state, payload) {
      state.fsw012s09.value = payload
    },
    setFilterLoad(state, payload) {
      state.filterLoad.value = payload
    },
    setActiveFetchListView(state, payload) {
      state.activeFetchListView.value = payload
    },
    setPageLoadSel(state, payload) {
      state.pageLoadSel.value = payload
    },
    setShowQuickView(state, payload) {
      state.showListingQuickView.value = payload
    },
    setListingSel(state, payload) {
      state.listingSel.value = payload
    },
    setInfoMkt(state, payload) {
      state.infoMkt.value = payload
    },
    setInfoConsultor(state, payload) {
      state.infoConsultor.value = payload
    },
    setInfoLead(state, payload) {
      state.infoDataLead.value = payload
    },
    setInfoBank(state, payload) {
      state.infoDataBank.value = payload
    },
    setInfoListing(state, payload) {
      state.infoListing.value = payload
    },
    setHeaderListing(state, payload) {
      state.headerListing.value = payload
    },
  },
  actions: {
    async getListview({ state, commit, rootGetters }, payload) {
      let pageListviewCustom = ''

      if (payload.pageSel === 'listings-listview-list') {
        pageListviewCustom = 'listings/list'
      } else if (payload.pageSel === 'listings-listview-pendentes') {
        pageListviewCustom = 'listings/pendentes'

        if ((localStorage.getItem(state.historyKeyStorage) === null) || (localStorage.getItem(state.historyKeyStorage) !== '')) {
          if (payload.pageSel !== 'listings-listview-externas') {
            state.estado.value = []
            state.estado.value.push(5)
          }
        }
      } else if (payload.pageSel === 'listings-listview-myHUBLocation') {
        pageListviewCustom = 'listings/myHUBLocation'
      } else if (payload.pageSel === 'listings-listview-myHUB') {
        pageListviewCustom = 'listings/myHUB'
      } else if (payload.pageSel === 'listings-listview-rede') {
        pageListviewCustom = 'listings/rede'
      } else if (payload.pageSel === 'listings-listview-externas') {
        pageListviewCustom = 'listings/externas'
      } else if (payload.pageSel === 'listings-favorites') {
        pageListviewCustom = 'listings/favorites'
      } else if (payload.pageSel === 'listings-myShares') {
        pageListviewCustom = 'listings/myShares'
      } else if (payload.pageSel === 'listings-shares') {
        pageListviewCustom = 'listings/shared'
      } else if (payload.pageSel === 'listings-shared') {
        pageListviewCustom = 'listings/sharedListings'
      } else if (payload.pageSel === 'listings-shared-HUB') {
        pageListviewCustom = 'listings/sharedListingsHUB'
      }

      commit('setPageSel', payload.pageSel)

      for (let index = 1; index < 71; index += 1) {
        state.array70.value.push({ id: index, desc: index })
      }

      return new Promise((resolve, reject) => {
        api.get(`${apiConfig.url_base_api}${pageListviewCustom}`)
          .then(response => {
            if (response.data.data !== undefined) {
              commit('setFilterTiposImoveis', response.data.data.tiposImoveisCategoria)
              commit('setFilterTipoNegocios', response.data.data.tipo_negocio)

              if (rootGetters['auth/countryApp'].toUpperCase() === 'PT') {
                if (checkNested(response, 'data.data.tipologias.Habitacional')) {
                  commit('setFilterTipologiasH', response.data.data.tipologias.Habitacional)
                }

                if (checkNested(response, 'data.data.tipologias.Misto')) {
                  commit('setFilterTipologiasM', response.data.data.tipologias.Misto)
                }
              } else if (rootGetters['auth/countryApp'].toUpperCase() === 'ES') {
                if (checkNested(response, 'data.data.tipologias.Viviendas')) {
                  commit('setFilterTipologiasH', response.data.data.tipologias.Viviendas)
                }

                if (checkNested(response, 'data.data.tipologias.Tierra')) {
                  commit('setFilterTipologiasM', response.data.data.tipologias.Tierra)
                }
              }

              if (checkNested(response, 'data.data.tipologias.Comercial')) {
                commit('setFilterTipologiasC', response.data.data.tipologias.Comercial)
              }

              if (checkNested(response, 'data.data.pageLoadSel')) {
                commit('setPageLoadSel', response.data.data.pageLoadSel)
              }

              commit('setFilterTipologiasInit', response.data.data.topTipologias)
              commit('setFilterTipologias', response.data.data.topTipologias)
              commit('setFilterCondicoesImovel', response.data.data.condicao_imovel)
              commit('setFilterTiposTerrenos', response.data.data.tipo_terreno)
              commit('setFilterClassesTipologias', response.data.data.classes_tipologias_geral)
              commit('setFilterEstadosListings', { pageSel: payload.pageSel, currentUser: rootGetters['auth/currentUser'] })
              commit('setAppConfig', response.data.data.configsAPP.app)
              commit('setFilterLoad', true)
              resolve(true)
            } else {
              commit('setFilterTiposImoveis', [])
              commit('setFilterTipoNegocios', [])
              commit('setFilterTipologiasH', [])
              commit('setFilterTipologiasC', [])
              commit('setFilterTipologiasM', [])
              commit('setFilterCondicoesImovel', [])
              commit('setFilterTiposTerrenos', [])
              reject(new Error(i18n.t('Problema ao carregar a listagem')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              commit('setFilterTiposImoveis', [])
              commit('setFilterTipoNegocios', [])
              commit('setFilterTipologiasH', [])
              commit('setFilterTipologiasC', [])
              commit('setFilterTipologiasM', [])
              commit('setFilterCondicoesImovel', [])
              reject(new Error(i18n.t('Problema ao carregar a listagem')))
            }
          })
      })
    },
    // Carregar listagem
    fetchListView({
      state, commit, dispatch, rootGetters,
    }, payload) {
      if (state.activeFetchListView.value === false) {
        return false
      }

      const listviewFilterParams = new FormData()

      commit('resetFilterSelectedTotalFields')

      if (state.estado.value !== '0') {
        commit('addFilterSelectedTotalFields')
      }

      if (state.tipoImovel.value !== '0') {
        commit('addFilterSelectedTotalFields')
      }

      if (state.tipologia.value !== '0') {
        commit('addFilterSelectedTotalFields')
      }

      if (state.tipoNegocio.value !== '0') {
        commit('addFilterSelectedTotalFields')
      }

      if (state.listviewOrderBy.value.column !== undefined) {
        listviewFilterParams.append('orderByColumn', state.listviewOrderBy.value.column)
      }

      if (state.listviewOrderBy.value.sort !== undefined) {
        listviewFilterParams.append('orderBySort', state.listviewOrderBy.value.sort)
      }

      if (state.pageSel.value === 'listings-listview-externas') {
        state.estadoExterna.value.forEach(oEstado => {
          if (oEstado !== 0) {
            listviewFilterParams.append('fEstados[]', oEstado)
          }
        })
      } else {
        state.estado.value.forEach(oEstado => {
          if (oEstado !== 0) {
            listviewFilterParams.append('fEstados[]', oEstado)
          }
        })
      }

      state.tipologia.value.forEach(oTipologia => {
        if (oTipologia !== 0) {
          listviewFilterParams.append('fsw012s07[]', oTipologia)
        }
      })

      state.tipoNegocio.value.forEach(oTipoNegocio => {
        if (oTipoNegocio !== 0) {
          listviewFilterParams.append('fTipoNegocio[]', oTipoNegocio)
        }
      })

      // Filtros pesquisa avançada
      if (state.fsw012s28.value.length > 0) {
        state.fsw012s28.value.forEach(row => {
          if (checkNested(row, 'id')) {
            listviewFilterParams.append('fsw012s28[]', row.id)
          } else {
            listviewFilterParams.append('fsw012s28[]', row)
          }
        })
      }

      if (state.fsw012s29.value.length > 0) {
        let fConcelhos = ''
        state.fsw012s29.value.forEach(row => {
          if (checkNested(row, 'id')) {
            fConcelhos += `${row.id};`
          } else {
            fConcelhos += `${row};`
          }
        })

        if (fConcelhos !== '') {
          listviewFilterParams.append('fsw012s29', fConcelhos.slice(0, -1))
        }
      }

      if (state.fsw012s30.value.length > 0) {
        let fFreguesias = ''
        state.fsw012s30.value.forEach(row => {
          if (checkNested(row, 'id')) {
            fFreguesias += `${row.id};`
          } else {
            fFreguesias += `${row};`
          }
        })

        if (fFreguesias !== '') {
          listviewFilterParams.append('fsw012s30', fFreguesias.slice(0, -1))
        }
      }

      if (state.fsw012s331.value.length > 0) {
        let fMunicipio = ''
        state.fsw012s331.value.forEach(row => {
          if (checkNested(row, 'id')) {
            fMunicipio += `${row.id};`
          } else {
            fMunicipio += `${row};`
          }
        })

        if (fMunicipio !== '') {
          listviewFilterParams.append('fsw012s331', fMunicipio.slice(0, -1))
        }
      }

      if (state.fsw012s298.value.length > 0) {
        let fZona = ''
        state.fsw012s298.value.forEach(row => {
          if (checkNested(row, 'id')) {
            fZona += `${row.id};`
          } else {
            fZona += `${row};`
          }
        })

        if (fZona !== '') {
          listviewFilterParams.append('fsw012s298', fZona.slice(0, -1))
        }
      }

      if (state.fsw012s11.value !== '') {
        let fCondicoes = ''
        state.fsw012s11.value.forEach(row => {
          fCondicoes += `${row.sw010s01};`
        })

        listviewFilterParams.append('fsw012s11', fCondicoes.slice(0, -1))
      }

      if (state.fsw012s26.value !== '' && state.fsw012s26.value !== null) {
        listviewFilterParams.append('fsw012s26', state.fsw012s26.value)
      }

      if (state.data.value !== '' && state.data.value !== null) {
        listviewFilterParams.append('fsw012_searchadv_tipo_datas', state.data.value.id)
      }

      if (state.atributo.value !== '') {
        state.atributo.value.forEach(id => {
          listviewFilterParams.append(id, 1)
        })
      }

      if (rootGetters['auth/countryApp'].toUpperCase() === 'PT' && typeof state.fsw012s08_desde.value.sw005s01 !== 'undefined') {
        listviewFilterParams.append('fsw012s08_desde', state.fsw012s08_desde.value.sw005s01)
      }

      if (rootGetters['auth/countryApp'].toUpperCase() === 'PT' && typeof state.fsw012s08_ate.value.sw005s01 !== 'undefined') {
        listviewFilterParams.append('fsw012s08_ate', state.fsw012s08_ate.value.sw005s01)
      }

      if (rootGetters['auth/countryApp'].toUpperCase() !== 'PT' && typeof state.fsw012s08_desde.value.id !== 'undefined') {
        listviewFilterParams.append('fsw012s08_desde', state.fsw012s08_desde.value.id)
      }

      if (rootGetters['auth/countryApp'].toUpperCase() !== 'PT' && typeof state.fsw012s08_ate.value.id !== 'undefined') {
        listviewFilterParams.append('fsw012s08_ate', state.fsw012s08_ate.value.id)
      }

      if (typeof state.fsw012s09.value.sw008s01 !== 'undefined') {
        listviewFilterParams.append('fsw012s09', state.fsw012s09.value.sw008s01)
      }

      // Por defeito, caso nao seja selecionada a pagina, faz sempre reset para a pagina "1"
      if ((payload !== null) && (payload !== undefined) && ('skipCurrentPage' in payload) && (payload.skipCurrentPage === true)) {
        // Fix para nao alterar a pagina que foi selecionada pelo utilizador
      } else if (state.currentPage.value !== 1) {
        commit('setCurrentPage', 1)
      }

      listviewFilterParams.append('fsw012s14_desde', state.fsw012s14_desde.value)
      listviewFilterParams.append('fsw012s14_ate', state.fsw012s14_ate.value)
      listviewFilterParams.append('fsw012_searchadv_desde', state.tipoData.value)
      listviewFilterParams.append('fsw012_searchadv_int_datehome', state.intervaloData.value)
      listviewFilterParams.append('fsw012_searchadv_int_dateend', state.intervaloDataEnd.value)
      listviewFilterParams.append('fsw012s312', state.tipoImovel.value)
      listviewFilterParams.append('start', ((state.currentPage.value - 1) * state.perPage.value))
      listviewFilterParams.append('length', state.perPage.value)

      if (state.searchTxt.value !== '') {
        listviewFilterParams.append('search[value]', state.searchTxt.value)
        listviewFilterParams.append('search[regex]', false)
        commit('addFilterSelectedTotalFields')
      }

      if (!!payload && (payload.loadingDisable !== undefined) && (payload.loadingDisable === true)) {
        commit('setListviewInit', 1)
      } else {
        commit('setLoadingListview', true)
        commit('setListviewData', [])
        commit('setTotalListings', 0)
        commit('setListviewInit', 1)
      }

      let loadListviewCustom = ''

      if (state.pageSel.value === 'listings-listview-list') {
        loadListviewCustom = 'listings/load'
      } else if (state.pageSel.value === 'listings-listview-pendentes') {
        loadListviewCustom = 'listings/loadListingsPendentesRede'
      } else if (state.pageSel.value === 'listings-listview-myHUBLocation') {
        loadListviewCustom = 'listings/loadListingsMyHUBLocation'
      } else if (state.pageSel.value === 'listings-listview-myHUB') {
        loadListviewCustom = 'listings/loadListingsMyHUB'
      } else if (state.pageSel.value === 'listings-listview-rede') {
        loadListviewCustom = 'listings/loadListingsRede'
      } else if (state.pageSel.value === 'listings-listview-externas') {
        loadListviewCustom = 'listings/loadListingsExternas'
      } else if (state.pageSel.value === 'listings-favorites') {
        loadListviewCustom = 'listings/loadListingsFavorites'
      } else if (state.pageSel.value === 'listings-myShares') {
        loadListviewCustom = 'listings/loadListingsMyShares'
      } else if (state.pageSel.value === 'listings-shares') {
        loadListviewCustom = 'listings/loadListingsShared'
      } else if (state.pageSel.value === 'listings-shared') {
        loadListviewCustom = 'listings/loadListingsSharedListings'
      } else if (state.pageSel.value === 'listings-shared-HUB') {
        loadListviewCustom = 'listings/loadListingsSharedListingsHUB'
      }

      commit('saveStates')

      return new Promise((resolve, reject) => {
        dispatch('request/cancelTokensPendingByUrl', loadListviewCustom, { root: true })

        api.post(`${apiConfig.url_base_api}${loadListviewCustom}`, listviewFilterParams)
          .then(response => {
            commit('setListviewData', response.data.data)
            commit('setTotalListings', Number(response.data.recordsTotal))
            commit('setListviewInit', 0)
            commit('setLoadingListview', false)
            resolve(true)
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              commit('setListviewInit', 0)
              reject(new Error(i18n.t('Problema ao carregar a listagem')))
            }
          })
      })
    },

    // Carrega os dados para o painel do rating
    async previewModalRating({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/previewModalRating`, payload)
          .then(response => {
            if (response.data.error === 1) {
              reject(new Error(response.data.msg))
            } else {
              commit('setLoadingListview', false)
              resolve(response.data)
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema a carregar painel do rating')))
            }
          })
      })
    },

    // Duplicar angariação
    async duplicateListing({ state }, payload) {
      const formData = new FormData()

      formData.append('sw012s01', state.idListing.value)
      formData.append('photos', payload.photos)

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/duplicateReg`, formData)
          .then(response => {
            if (typeof response.data !== 'object') {
              reject(new Error(i18n.t('Problema ao duplicar angariação')))
            } else {
              resolve(response.data)
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao duplicar angariação')))
            }
          })
      })
    },

    // Adicionar aos favoritos
    async addFavorite({ state }) {
      const formData = new FormData()

      formData.append('idReg', state.idListing.value)

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/addFavorite`, formData)
          .then(response => {
            if (typeof response.data !== 'object') {
              reject(new Error(i18n.t('Problema ao adicionar angariação aos favoritos')))
            } else {
              resolve(response.data)
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao adicionar angariação aos favoritos')))
            }
          })
      })
    },

    // Remover dos favoritos
    async removeFavorite({ state }) {
      const formData = new FormData()

      formData.append('idReg', state.idListing.value)

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/delFavorite`, formData)
          .then(response => {
            if (typeof response.data !== 'object') {
              reject(new Error(i18n.t('Problema ao remover angariação dos favoritos')))
            } else {
              resolve(response.data)
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao remover angariação dos favoritos')))
            }
          })
      })
    },

    // Suspender a angariação
    async suspenderReg({ state }) {
      const formData = new FormData()

      formData.append('regDel', state.idListing.value)

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/suspenderReg`, formData)
          .then(response => {
            if (typeof response.data !== 'object') {
              reject(new Error(i18n.t('Problema a suspender a angariação')))
            } else {
              resolve(response.data)
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema a suspender a angariação')))
            }
          })
      })
    },

    // Eliminar a angariação
    async deleteListing({ state }, payload) {
      const formData = new FormData()

      formData.append('regDel', state.idListing.value)

      if (typeof payload.config === 'object') {
        Object.keys(payload.config).forEach(index => {
          formData.append(index, payload.config[index])
        })
      }

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/delete`, formData)
          .then(response => {
            if (typeof response.data !== 'object') {
              reject(new Error(i18n.t('Problema a eliminar a angariação')))
            } else {
              resolve(response.data)
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema a eliminar a angariação')))
            }
          })
      })
    },

    // Restaurar a angariação
    async restoreListing({ state }) {
      const formData = new FormData()

      formData.append('regDel', state.idListing.value)

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/restore`, formData)
          .then(response => {
            if (typeof response.data !== 'object') {
              reject(new Error(i18n.t('Problema a restaurar angariação')))
            } else {
              resolve(response.data)
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema a restaurar angariação')))
            }
          })
      })
    },

    // Gerar link de partilha
    async gerarPartilhaConsultor({ state }, payload) {
      const formData = new FormData()

      formData.append('idListing', state.idListing.value)
      formData.append('equipaOpcao', payload.equipaOpcao || '')
      formData.append('sw012s378', 1)

      if (Number(payload.sw139s11) === 1) {
        formData.append('sw139s11', 1)
      }

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/partilha/gerarPartilhaConsultor`, formData)
          .then(response => {
            if (typeof response.data !== 'object') {
              reject(new Error(i18n.t('Problema a gerar link de partilha')))
            } else {
              resolve(response.data)
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema a gerar link de partilha')))
            }
          })
      })
    },

    // Carrega a vista rápida da angariação
    async previewQuickListing({ state, commit }, payload) {
      let sUrlVistaRapida = ''

      commit('setLoadingListview', true)

      if (typeof payload !== 'undefined' && typeof payload.keyReg !== 'undefined') {
        sUrlVistaRapida = `${apiConfig.url_base_api}listings/previewReg/${payload.keyReg}`
      } else {
        sUrlVistaRapida = `${apiConfig.url_base_api}listings/previewReg/${state.idListing.value}`

        if (Number(state.listingSel.value.showVersionPending) === 1) {
          sUrlVistaRapida += '/pending'
        } else if (state.pageLoadSel.value !== '') {
          sUrlVistaRapida += `/${state.pageLoadSel.value}`
        }
      }

      return new Promise((resolve, reject) => {
        api.get(sUrlVistaRapida)
          .then(response => {
            if (typeof response.data === 'undefined') {
              commit('setLoadingListview', false)
              reject(new Error(i18n.t('Problema a carregar a vista rápida')))
            } else {
              if (checkNested(response, 'data.data.infoMkt')) {
                commit('setInfoMkt', response.data.data.infoMkt)
              }

              if (checkNested(response, 'data.data.infoConsultor')) {
                commit('setInfoConsultor', response.data.data.infoConsultor)
              }

              if (checkNested(response, 'data.data.infoLead')) {
                commit('setInfoLead', response.data.data.infoLead)
              }

              if (checkNested(response, 'data.data.infoBank')) {
                commit('setInfoBank', response.data.data.infoBank)
              }

              if (checkNested(response, 'data.data.sw012')) {
                commit('setInfoListing', response.data.data.sw012)
              }

              if (checkNested(response, 'data.header')) {
                commit('setHeaderListing', response.data.header)
              }

              if (state.headerListing.value.images.length === 0) {
                state.headerListing.value.images.push(state.headerListing.value.imagePreview)
              }

              commit('setLoadingListview', false)
              commit('setShowQuickView', true)

              if (typeof payload !== 'undefined' && typeof payload.keyReg !== 'undefined') {
                commit('setOptionsListing', response.data.header.optionsAction)
                commit('setIdListing', btoa(response.data.data.sw012.sw012s01))
                commit('setPid', response.data.header.pid)
                commit('setAppConfig', response.data.header.configsAPP)
              }

              resolve(true)
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              commit('setLoadingListview', false)
              reject(new Error(i18n.t('Problema a carregar a vista rápida')))
            }
          })
      })
    },

    // Ordenação da listagem
    listviewFilterOrderBy({ state, commit, dispatch }, field) {
      if ((!!state.listviewOrderBy.value.column) && (state.listviewOrderBy.value.column === field)) {
        if (state.listviewOrderBy.value.sort === 'asc') {
          commit('setListviewOrderBy', {
            sort: 'desc',
            column: field,
          })
        } else {
          commit('setListviewOrderBy', {
            sort: 'asc',
            column: field,
          })
        }
      } else {
        commit('setListviewOrderBy', {
          sort: 'asc',
          column: field,
        })
      }

      return dispatch('fetchListView')
    },

    setSortBy({ commit }, value) {
      commit('setSortBy', value)
    },

    pullToRefreshListview({ commit, dispatch }) {
      commit('setCurrentPage', 1)
      return dispatch('fetchListView', { loadingDisable: true })
    },

    setSearchTxt({ commit, dispatch }, value) {
      commit('setSearchTxt', value)
      return dispatch('fetchListView', { loadingDisable: true })
    },

    setCurrentPage({ commit, dispatch }, value) {
      commit('setCurrentPage', value)
      return dispatch('fetchListView', { skipCurrentPage: true })
    },

    // Metodos do filtro lateral - estado
    selectEstado({ commit, dispatch }, value) {
      commit('setFilterEstado', value)
      dispatch('fetchListView')
    },

    selectEstadoExterna({ commit, dispatch }, value) {
      commit('setFilterEstadoExterna', value)
      dispatch('fetchListView')
    },

    selectTipoImovel({ commit, dispatch }, value) {
      commit('setFilterTipoImovel', value)
      commit('setFilterTipologia', 0)
      dispatch('fetchListView')
    },

    selectTipoNegocio({ commit, dispatch }, value) {
      commit('setFilterTipoNegocio', value)
      dispatch('fetchListView')
    },

    selectTipologia({ commit, dispatch, getters }, value) {
      commit('setFilterTipologia', value)
      commit('setFilterTipologiaClearFields', getters.showFilterTipologia)
      dispatch('fetchListView')
    },

    openSearchAdvance({ commit, dispatch }, value) {
      if (value === 'outline-primary') {
        commit('setButtonSearch', 'primary')
      } else {
        commit('resetAllFilterSearchAdvance')
        commit('setButtonSearch', 'outline-primary')
        dispatch('fetchListView')
      }
    },

    resetFilterSearchAdvance({ commit, dispatch }) {
      commit('resetAllFilterSearchAdvance')
      return dispatch('fetchListView')
    },

    selectFilterOptions({ commit, dispatch }, params) {
      if (params[0] === 'fsw012s08_desde') {
        if (params[1] === null) {
          commit('setFsw012s08_desde', '')
        } else {
          commit('setFsw012s08_desde', params[1])
        }
      } else if (params[0] === 'fsw012s08_ate') {
        if (params[1] === null) {
          commit('setFsw012s08_ate', '')
        } else {
          commit('setFsw012s08_ate', params[1])
        }
      } else if (params[0] === 'fsw012s14_desde') {
        if (params[1] === null || params[1] === '0,00') {
          commit('setFsw012s14_desde', 0)
        } else {
          commit('setFsw012s14_desde', params[1])
        }
      } else if (params[0] === 'fsw012s14_ate') {
        if (params[1] === null || params[1] === '0,00') {
          commit('setFsw012s14_ate', 0)
        } else {
          commit('setFsw012s14_ate', params[1])
        }
      } else if (params[0] === 'fsw012s11') {
        const filters = []
        params[1].forEach(row => {
          const filter = row
          filters.push(filter)
        })

        commit('setFsw012s11', filters)
      } else if (params[0] === 'fsw012s26') {
        commit('setFsw012s26', params[1])
      } else if (params[0] === 'fsw012_searchadv_tipo_datas') {
        commit('setData', params[1])
      } else if (params[0] === 'fsw012s09') {
        commit('setFsw012s09', params[1])
      }

      return dispatch('fetchListView')
    },

    selectAtributo({ commit, dispatch }, value) {
      commit('setAtributo', value)
      dispatch('fetchListView')
    },

    selectTipoData({ commit, dispatch }, value) {
      commit('setTipoData', value)
      dispatch('fetchListView')
    },

    selectFilterIntervaloData({ commit, dispatch }, value) {
      commit('setIntervaloData', value)
      dispatch('fetchListView')
    },

    selectFilterIntervaloDataEnd({ commit, dispatch }, value) {
      commit('setIntervaloDataEnd', value)
      dispatch('fetchListView')
    },

    setShowOptions({ commit }, params) {
      commit('setShowOptions', params[0])
      if (params[1] !== undefined && params[1] !== null && params[2] !== undefined && params[2] !== null) {
        commit('setIdListing', params[1])
        commit('setPid', params[2])
        commit('setOptionsListing', params[3])
      }
    },

    async initStates({ state, commit }) {
      commit('initStates')

      if ((state.tipoImovel !== null) && (state.tipoImovel !== undefined) && ('value' in state.tipoImovel)) {
        commit('setFilterTipoImovel', state.tipoImovel.value)
      }
    },

    async setActiveFetchListView({ commit }, payload) {
      commit('setActiveFetchListView', payload)
    },

    setShowListingQuickView({ commit, dispatch }, params) {
      if (params[1] !== null) {
        commit('setListingSel', params[1])
        commit('setOptionsListing', params[1])
        commit('setIdListing', params[1].idListing)
        dispatch('previewQuickListing')
      } else {
        commit('setShowQuickView', params[0])
        commit('setListingSel', {})
        commit('setIdListing', '')
        commit('setInfoMkt', {})
        commit('setInfoConsultor', {})
        commit('setInfoLead', {})
        commit('setInfoBank', {})
        commit('setInfoListing', {})
        commit('setHeaderListing', {})
        commit('setOptionsListing', {})
      }
    },

    async getMarketStudy(state, payload) {
      return new Promise((resolve, reject) => {
        let urlRequestData = 'estudoMercado/analise'
        const { id } = payload

        if ((id !== undefined) && (id !== '')) {
          urlRequestData = `estudoMercado/analise/${id}`
        }

        api.get(`${apiConfig.url_base_api}${urlRequestData}`)
          .then(response => {
            try {
              if (checkNested(response, 'data.data.casafariUrl')) {
                resolve(response.data.data.casafariUrl)
              } else {
                reject(new Error(i18n.t('Problema ao carregar o estudo do mercado')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar o estudo do mercado')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar o estudo do mercado')))
            }
          })
      })
    },

  },
}
